import LiftService from "@/services/lift.service";

const processJob = {}
const processJobs = {
    page: 1,
    itemsPerPage: 10,
    limit: null,
    total: 0,
    list: []
}

const processJobModule = {
    namespaced: true,
    state: {processJob, processJobs},
    actions: {
        CREATE({commit}, payload) {
            commit('loading/PROCESS_JOB_CREATE', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.PROCESS_JOB_CREATE(
                    payload.processJobUniqueId,
                    payload.processJobDescShort,
                    payload.processJobDescLong,
                    payload.processJobComment,
                    payload.processJobType,
                    payload.processJobStatus
                )
                    .then(processJob => {
                        commit('loading/PROCESS_JOB_CREATE', false, {root: true});
                        commit('populateProcessJob', processJob);
                        resolve(processJob);
                    })
                    .catch(error => {
                        commit('loading/PROCESS_JOB_CREATE', false, {root: true});
                        reject(error);
                    });
            });
        },
        GET({commit}, processJobUniqueId) {
            commit('loading/PROCESS_JOB_GET', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.PROCESS_JOB_GET(processJobUniqueId)
                    .then(processJob => {
                        commit('loading/PROCESS_JOB_GET', false, {root: true});
                        commit('populateProcessJob', processJob);
                        resolve(processJob);
                    })
                    .catch(error => {
                        commit('loading/PROCESS_JOB_GET', false, {root: true});
                        reject(error);
                    });
            });
        },
        UPDATE({commit}, payload) {
            commit('loading/PROCESS_JOB_UPDATE', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.PROCESS_JOB_UPDATE(
                    payload.processJobUniqueId,
                    payload.processJobDescShort,
                    payload.processJobDescLong,
                    payload.processJobComment,
                    payload.processJobType,
                    payload.processJobStatus
                )
                    .then(processJob => {
                        commit('loading/PROCESS_JOB_UPDATE', false, {root: true});
                        commit('populateProcessJob', processJob);
                        resolve(processJob);
                    })
                    .catch(error => {
                        commit('loading/PROCESS_JOB_UPDATE', false, {root: true});
                        reject(error);
                    });
            });
        },
        DELETE({commit}, processJobUniqueId) {
            commit('loading/PROCESS_JOB_DELETE', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.PROCESS_JOB_DELETE(processJobUniqueId)
                    .then(processJob => {
                        commit('loading/PROCESS_JOB_DELETE', false, {root: true});
                        commit('populateProcessJob', processJob);
                        resolve(processJob);
                    })
                    .catch(error => {
                        commit('loading/PROCESS_JOB_DELETE', false, {root: true});
                        reject(error);
                    });
            });
        },
        LIST({commit}, payload) {
            commit('loading/PROCESS_JOB_LIST', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.PROCESS_JOB_LIST(
                    payload.filter,
                    payload.order,
                    payload.page,
                    payload.itemsPerPage
                )
                    .then(processJobs => {
                        commit('loading/PROCESS_JOB_LIST', false, {root: true});
                        commit('populateProcessJobs', processJobs);
                        resolve(processJobs);
                    })
                    .catch(error => {
                        commit('clearProcessJobs');
                        commit('loading/PROCESS_JOB_LIST', false, {root: true});
                        reject(error);
                    });
            });
        },
    },
    mutations: {
        clearProcessJobs(state) {
            state.processJobs = {
                page: 1,
                itemsPerPage: 10,
                limit: null,
                total: 0,
                list: []
            }
        },
        populateProcessJobs(state, processJobs) {
            state.processJobs = processJobs;
        },
        populateProcessJob(state, processJob) {
            state.processJob = processJob;
        },
    }
};

export default processJobModule;
