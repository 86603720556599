import LiftService from "@/services/lift.service";

const serviceProvider = {}
const serviceProviders = {
    page: 1,
    itemsPerPage: 10,
    limit: null,
    total: 0,
    list: []
}
const serviceProviderService = {}
const serviceProviderServices = {
    page: 1,
    itemsPerPage: 10,
    limit: null,
    total: 0,
    list: []
}

const serviceProviderServiceModule = {
    namespaced: true,
    state: {serviceProvider, serviceProviders, serviceProviderService, serviceProviderServices},
    actions: {
        LIST({commit}, payload) {
            commit('loading/SERVICE_PROVIDER_SERVICE_LIST', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.SERVICE_PROVIDER_SERVICE_LIST(
                    payload.filter,
                    payload.order,
                    payload.page,
                    payload.itemsPerPage
                )
                    .then(serviceProviderServices => {
                        commit('populateServiceProviderServices', serviceProviderServices);
                        commit('loading/SERVICE_PROVIDER_SERVICE_LIST', false, {root: true});
                        resolve(serviceProviderServices);
                    })
                    .catch(error => {
                        commit('clearServiceProviderServices');
                        commit('loading/SERVICE_PROVIDER_SERVICE_LIST', false, {root: true});
                        reject(error);
                    });
            });
        },
    },
    mutations: {
        clearServiceProviderServices(state) {
            state.serviceProviderServices = {
                page: 1,
                itemsPerPage: 10,
                limit: null,
                total: 0,
                list: []
            }
        },
        populateServiceProviderServices(state, serviceProviderServices) {
            state.serviceProviderServices = serviceProviderServices;
        },
    }
};

export default serviceProviderServiceModule;