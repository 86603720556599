import {v4 as v4uuid} from 'uuid';
import Service from "../lib/service";
import feature from "../lib/feature-collection";

export default new class LiftService extends Service {

    /**
     * @param {String} username
     * @param {String} password
     * @returns {Promise<Object>}
     */
    USER_LOGIN(username, password) {
        const payload = {username, password}

        return this.request('USER_LOGIN', null, '', payload , 'PUBLIC');
    }

    /**
     * @param {Object} username
     * @returns {Promise<Object>}
     */
    USER_LOST_PASSWORD(username) {
        const payload = {
            username,
            passwordResetUrl: process.env.VUE_APP_DOMAIN + '/password-reset',
            passwordResetExpiresInSeconds: 86400
        }

        return this.request('USER_LOST_PASSWORD', null, '', payload, 'PUBLIC')
    }

    /**
     * @param {String} token
     * @param {String} newPassword
     * @returns {Promise<Object>}
     */
    USER_RECOVER_PASSWORD(token, newPassword) {
        const payload = {token, newPassword}

        return this.request('USER_RECOVER_PASSWORD', null, '', payload, 'PUBLIC');
    }

    /**
     * @returns {Promise<Object>}
     */
    USER_GET_PROFILE() {
        return this.request('USER_GET_PROFILE', feature.general, '', {});
    }

    /**
     * @param {String} email
     * @param {String} vatId
     * @param {String} salutation
     * @param {String} firstName
     * @param {String} lastName
     * @param {String} company
     * @param {String} street
     * @param {String} postcode
     * @param {String} city
     * @param {String} state
     * @param {String} country
     * @param {String} birthday
     * @param {Object} billingAddress
     * @param {Object} shippingAddress
     * @returns {Promise<Object>}
     */
    USER_UPDATE_PROFILE(
        email,
        vatId,
        salutation,
        firstName,
        lastName,
        company,
        street,
        postcode,
        city,
        state,
        country,
        birthday,
        billingAddress,
        shippingAddress
    ) {
        return this.request('USER_UPDATE_PROFILE', feature.general, '', {
            email,
            vatId,
            salutation,
            firstName,
            lastName,
            company,
            street,
            postcode,
            city,
            state,
            country,
            birthday,
            billingAddress,
            shippingAddress
        });
    }

    /**
     * @param {String} username
     * @param {String} password
     * @param {String} newPassword
     * @returns {Promise<Object>}
     */
    USER_UPDATE_PASSWORD(username, password, newPassword) {
        return this.request('USER_UPDATE_PASSWORD', feature.general, '', {
            username,
            password,
            newPassword
        });
    }

    /**
     * @param {Object} filter
     * @param {Object} order
     * @param {Number} page
     * @param {Number} itemsPerPage
     * @param {Number} limit
     * @returns {Promise<Object>}
     */
    CAMPAIGN_LIST(filter, order, page, itemsPerPage, limit) {
        return this.request('CAMPAIGN_LIST', feature.general, '', {
            filter,
            order,
            page,
            itemsPerPage,
            limit
        });
    }

    /**
     * @param {String} campaignDescShort
     * @param {String} campaignDescLong
     * @param {String} campaignComment
     * @param {String} campaignType
     * @param {?String} campaignReuseCampaignUniqueId
     * @param {[Object]} inboundChannels
     * @param {[Object]} outboundChannels
     * @returns {Promise<Object>}
     */
    CAMPAIGN_CREATE(
        campaignDescShort,
        campaignDescLong,
        campaignComment,
        campaignType,
        campaignReuseCampaignUniqueId,
        inboundChannels,
        outboundChannels
    ) {
        return this.request('CAMPAIGN_CREATE', feature.campaignRegularManual, '', {
            nodeUniqueId: this.nodeUniqueId,
            campaignUniqueId: v4uuid(),
            campaignDescShort,
            campaignDescLong,
            campaignComment,
            campaignType,
            campaignReuseCampaignUniqueId,
            inboundChannels,
            outboundChannels
        });
    }

    /**
     * @param {String} campaignUniqueId
     * @param {String} campaignDescShort
     * @param {String} campaignDescLong
     * @param {String} campaignComment
     * @param {String} campaignType
     * @param {String} campaignStatus
     * @param {String} mboxEmail
     * @param {[Object]} inboundChannels
     * @param {[Object]} outboundChannels
     * @returns {Promise<Object>}
     */
    CAMPAIGN_UPDATE(
        campaignUniqueId,
        campaignDescShort,
        campaignDescLong,
        campaignComment,
        campaignType,
        campaignStatus,
        mboxEmail,
        inboundChannels,
        outboundChannels,
    ) {
        return this.request('CAMPAIGN_UPDATE', feature.general, '', {
            campaignUniqueId,
            campaignDescShort,
            campaignDescLong,
            campaignComment,
            campaignType,
            campaignStatus,
            mboxEmail,
            inboundChannels,
            outboundChannels
        });
    }

    /**
     * @param {String} campaignUniqueId
     * @returns {Promise<Object>}
     */
    CAMPAIGN_GET(campaignUniqueId) {
        return this.request('CAMPAIGN_GET', feature.general, '', {campaignUniqueId});
    }

    /**
     * @param {String} campaignUniqueId
     * @param {Array} filter
     * @returns {Promise<Object>}
     */
    CAMPAIGN_LIST_TRIGGER(campaignUniqueId, filter) {
        return this.request('CAMPAIGN_LIST_TRIGGER', feature.general, '', {
            campaignUniqueId,
            filter
        });
    }

    /**
     * @param {String}  campaignUniqueId
     * @param {String}  channelUniqueId
     * @param {Boolean} saveExternalCampaign
     * @param {Object}  configData
     * @returns {Promise<Object>}
     */
    CAMPAIGN_UPDATE_CHANNEL(campaignUniqueId, channelUniqueId, saveExternalCampaign, configData) {
        return this.request('CAMPAIGN_UPDATE_CHANNEL', feature.general, '', {
            nodeUniqueId: this.nodeUniqueId,
            campaignUniqueId,
            channelUniqueId,
            saveExternalCampaign,
            configData
        });
    }


    /**
     * @param {String} campaignUniqueId
     * @param {String} sourceType
     * @param {String} sourceName
     * @param {Array} contactUniqueIds
     * @param {Array} segmentUniqueIds
     * @param {String} ruleUniqueId
     * @param {Object} importConfig
     * @returns {Promise<Object>}
     */
    CAMPAIGN_SAVE_RECIPIENT_CONFIG(
        campaignUniqueId,
        sourceType,
        sourceName,
        contactUniqueIds,
        segmentUniqueIds,
        ruleUniqueId,
        importConfig
    ) {
        return this.request('CAMPAIGN_SAVE_RECIPIENT_CONFIG', feature.general, '', {
            campaignUniqueId,
            sourceType,
            sourceName,
            contactUniqueIds,
            segmentUniqueIds,
            ruleUniqueId,
            importConfig
        });
    }

    /**
     * @param {String} campaignUniqueId
     * @returns {Promise<Object>}
     */
    CAMPAIGN_GET_RECIPIENT_CONFIG(campaignUniqueId) {
        return this.request('CAMPAIGN_GET_RECIPIENT_CONFIG', feature.general, '', {campaignUniqueId});
    }

    /**
     * @param {String} campaignUniqueId
     * @param {Number} limit
     * @returns {Promise<Object>}
     */
    CAMPAIGN_PREVIEW_RECIPIENT(campaignUniqueId, limit) {
        return this.request('CAMPAIGN_PREVIEW_RECIPIENT', feature.general, '', {campaignUniqueId, limit});
    }

    /**
     * @param {String} campaignUniqueId
     * @param {String} channelUniqueId
     * @returns {Promise<Object>}
     */
    EVENT_TRIGGER_CAMPAIGN(campaignUniqueId, channelUniqueId) {
        return this.request('EVENT_TRIGGER_CAMPAIGN', feature.general, '', {
            campaignUniqueId,
            channelUniqueId,
            triggerSourceType: 'RECIPIENT_CONFIG',
            channelData: {
                printNode: 'Print_Node_01',
                campaignVariables: []
            }
        });
    }

    /**
     * @param {String} channelDescShort
     * @param {String} channelDescLong
     * @param {String} channelComment
     * @param {String} channelType
     * @param {String} serviceProviderServiceUniqueId
     * @returns {Promise<Object>}
     */
    CHANNEL_CREATE(
        channelDescShort,
        channelDescLong,
        channelComment,
        channelType,
        serviceProviderServiceUniqueId
    ) {
        return this.request('CHANNEL_CREATE', feature.general, '', {
            channelUniqueId: v4uuid(),
            channelDescShort,
            channelDescLong,
            channelComment,
            channelType,
            serviceProviderServiceUniqueId
        });
    }

    /**
     * @param {String} channelUniqueId
     * @returns {Promise<Object>}
     */
    CHANNEL_GET(channelUniqueId) {
        return this.request('CHANNEL_GET', feature.general, '', {channelUniqueId});
    }

    /**
     * @param {String} channelUniqueId
     * @param {String} channelDescShort
     * @param {String} channelDescLong
     * @param {String} channelComment
     * @param {String} channelType
     * @param {String} channelStatus
     * @param {String} serviceProviderServiceUniqueId
     * @param {String} mboxEmail
     * @returns {Promise<Object>}
     */
    CHANNEL_UPDATE(
        channelUniqueId,
        channelDescShort,
        channelDescLong,
        channelComment,
        channelType,
        channelStatus,
        serviceProviderServiceUniqueId,
        mboxEmail
    ) {
        return this.request('CHANNEL_UPDATE', feature.general, '', {
            channelUniqueId,
            channelDescShort,
            channelDescLong,
            channelComment,
            channelType,
            channelStatus,
            serviceProviderServiceUniqueId,
            mboxEmail
        });
    }

    /**
     * @param channelUniqueId
     * @returns {Promise<Object>}
     */
    CHANNEL_DELETE(channelUniqueId) {
        return this.request('CHANNEL_DELETE', feature.general, '', {channelUniqueId});
    }

    /**
     * @param {Object} filter
     * @param {Object} order
     * @param {Number} page
     * @param {Number} itemsPerPage
     * @returns {Promise<Object>}
     */
    CHANNEL_LIST(filter, order, page, itemsPerPage) {
        return this.request('CHANNEL_LIST', feature.general, '', {
            filter,
            order,
            page,
            itemsPerPage
        });
    }

    /**
     * @param {Object} filter
     * @param {Object} order
     * @param {Number} page
     * @param {Number} itemsPerPage
     * @returns {Promise<Object>}
     */
    SERVICE_PROVIDER_SERVICE_LIST(filter, order, page, itemsPerPage) {
        return this.request('SERVICE_PROVIDER_SERVICE_LIST', feature.general, '', {
            filter,
            order,
            page,
            itemsPerPage
        });
    }

    /**
     * @param {String} contactUniqueId
     * @returns {Promise<Object>}
     */
    CONTACT_GET(contactUniqueId) {
        return this.request('CONTACT_GET', feature.general, '', {contactUniqueId});
    }

    /**
     * @param {Object} address
     * @param {Object} contactInformation
     * @returns {Promise<Object>}
     */
    CONTACT_CREATE(address, contactInformation) {
        return this.request('CONTACT_CREATE', feature.general, '',{
            contactUniqueId: v4uuid(),
            address,
            contactInformation
        });
    }

    /**
     * @param {String} contactUniqueId
     * @param {Object} address
     * @param {Object} contactInformation
     * @returns {Promise<Object>}
     */
    CONTACT_UPDATE(contactUniqueId, address, contactInformation) {
        return this.request('CONTACT_UPDATE', feature.general, '', {
            contactUniqueId,
            address,
            contactInformation
        });
    }

    /**
     * @param {String} contactUniqueId
     * @returns {Promise<Object>}
     */
    CONTACT_DELETE(contactUniqueId) {
        return this.request('CONTACT_DELETE', feature.general, '', contactUniqueId);
    }

    /**
     * @param {Object} filter
     * @param {Object} order
     * @param {Number} page
     * @param {Number} itemsPerPage
     * @param {Number} limit
     * @returns {Promise<Object>}
     */
    CONTACT_LIST(filter, order, page, itemsPerPage, limit) {
        return this.request('CONTACT_LIST', feature.general, '', {
            filter,
            order,
            page,
            itemsPerPage,
            limit
        });
    }

    /**
     * @param {String} segmentDescShort
     * @param {String} segmentDescLong
     * @param {String} segmentComment
     * @returns {Promise<Object>}
     */
    SEGMENT_CREATE(segmentDescShort, segmentDescLong, segmentComment) {
        return this.request('SEGMENT_CREATE', feature.general, '', {
            segmentUniqueId: v4uuid(),
            segmentDescShort,
            segmentDescLong,
            segmentComment
        });
    }

    /**
     * @param segmentUniqueId
     * @returns {Promise<unknown>}
     */
    SEGMENT_GET(segmentUniqueId) {
        return this.request('SEGMENT_GET', feature.general, '', segmentUniqueId);
    }

    /**
     * @param {String} segmentUniqueId
     * @param {String} segmentDescShort
     * @param {String} segmentDescLong
     * @param {String} segmentComment
     * @returns {Promise<Object>}
     */
    SEGMENT_UPDATE(segmentUniqueId, segmentDescShort, segmentDescLong, segmentComment) {
        return this.request('SEGMENT_UPDATE', feature.general, '', {
            segmentUniqueId,
            segmentDescShort,
            segmentDescLong,
            segmentComment
        });
    }

    /**
     * @param {Object} segmentUniqueId
     * @returns {Promise<Object>}
     */
    SEGMENT_DELETE(segmentUniqueId) {
        return this.request('SEGMENT_DELETE', feature.general, '', segmentUniqueId);
    }

    /**
     * @param {Object} filter
     * @param {Object} order
     * @param {Number} page
     * @param {Number} itemsPerPage
     * @param {Number} limit
     * @returns {Promise<Object>}
     */
    SEGMENT_LIST(filter, order, page, itemsPerPage, limit) {
        return this.request('SEGMENT_LIST', feature.general, '', {
            filter,
            order,
            page,
            itemsPerPage,
            limit
        });
    }

    /**
     * @param {String} segmentUniqueId
     * @param {String} contactUniqueId
     * @returns {Promise<Object>}
     */
    SEGMENT_ADD_CONTACT(segmentUniqueId, contactUniqueId) {
        return this.request('SEGMENT_ADD_CONTACT', feature.general, '', {
            segmentUniqueId,
            contactUniqueId
        });
    }

    /**
     * @param {String} segmentUniqueId
     * @param {String} contactUniqueId
     * @returns {Promise<Object>}
     */
    SEGMENT_REMOVE_CONTACT(segmentUniqueId, contactUniqueId) {
        return this.request('SEGMENT_REMOVE_CONTACT', feature.general, '', {
            segmentUniqueId,
            contactUniqueId
        });
    }

    /**
     * @param {Object} filter
     * @param {Object} order
     * @param {Number} page
     * @param {Number} itemsPerPage
     * @returns {Promise<Object>}
     */
    MEDIA_LIBRARY_LIST(filter, order, page, itemsPerPage) {
        return this.request('MEDIA_LIBRARY_LIST', feature.general, '', {
            filter,
            order,
            page,
            itemsPerPage
        });

    }

    /**
     * @param {String} mediaObjectUniqueId
     * @param {String} mediaObjectDescShort
     * @param {String} mediaObjectDescLong
     * @param {String} mediaObjectComment
     * @param {String} mediaLibraryUniqueId
     * @param {String} mediaObjectType
     * @param {String} mediaObjectStatus
     * @param {Object} mediaProperties
     * @returns {Promise<Object>}
     */
    MEDIA_OBJECT_CREATE(
        mediaObjectUniqueId,
        mediaObjectDescShort,
        mediaObjectDescLong,
        mediaObjectComment,
        mediaLibraryUniqueId,
        mediaObjectType,
        mediaObjectStatus,
        mediaProperties
    ) {
        return this.request('MEDIA_OBJECT_CREATE', feature.general, '', {
            mediaObjectUniqueId,
            mediaObjectDescShort,
            mediaObjectDescLong,
            mediaObjectComment,
            mediaLibraryUniqueId,
            mediaObjectType,
            mediaObjectStatus,
            mediaProperties
        });
    }

    /**
     * @param {String} mediaObjectUniqueId
     * @returns {Promise<Object>}
     */
    MEDIA_OBJECT_GET(mediaObjectUniqueId) {
        return this.request('MEDIA_OBJECT_GET', feature.general, '', {mediaObjectUniqueId});
    }

    /**
     * @param {String} mediaObjectUniqueId
     * @param {String} mediaObjectDescShort
     * @param {String} mediaObjectDescLong
     * @param {String} mediaObjectComment
     * @param {String} mediaObjectType
     * @param {String} mediaObjectStatus
     * @returns {Promise<Object>}
     */
    MEDIA_OBJECT_UPDATE(
        mediaObjectUniqueId,
        mediaObjectDescShort,
        mediaObjectDescLong,
        mediaObjectComment,
        mediaObjectType,
        mediaObjectStatus
    ) {
        return this.request('MEDIA_OBJECT_UPDATE', feature.general, '', {
            mediaObjectUniqueId,
            mediaObjectDescShort,
            mediaObjectDescLong,
            mediaObjectComment,
            mediaObjectType,
            mediaObjectStatus
        });
    }

    /**
     * @param {String} mediaObjectUniqueId
     * @returns {Promise<Object>}
     */
    MEDIA_OBJECT_DELETE(mediaObjectUniqueId) {
        return this.request('MEDIA_OBJECT_DELETE', feature.general, '', {mediaObjectUniqueId});
    }

    /**
     * @param {Object} filter
     * @param {Object} order
     * @param {Number} page
     * @param {Number} itemsPerPage
     * @returns {Promise<Object>}
     */
    MEDIA_OBJECT_LIST(filter, order, page, itemsPerPage) {
        return this.request('MEDIA_OBJECT_LIST', feature.general, '', {
            filter,
            order,
            page,
            itemsPerPage
        });
    }


    /**
     * @param {String} processJobUniqueId
     * @param {String} processJobDescShort
     * @param {String} processJobDescLong
     * @param {String} processJobComment
     * @param {String} processJobType
     * @param {String} processJobStatus
     * @returns {Promise<Object>}
     */
    PROCESS_JOB_CREATE(
        processJobUniqueId,
        processJobDescShort,
        processJobDescLong,
        processJobComment,
        processJobType,
        processJobStatus,
    ) {
        return this.request('PROCESS_JOB_CREATE', feature.general, '', {
            processJobUniqueId,
            processJobDescShort,
            processJobDescLong,
            processJobComment,
            processJobType,
            processJobStatus
        });
    }

    /**
     * @param {String} processJobUniqueId
     * @returns {Promise<Object>}
     */
    PROCESS_JOB_GET(processJobUniqueId) {
        return this.request('PROCESS_JOB_GET', feature.general, '', {processJobUniqueId});
    }

    /**
     * @param {String} processJobUniqueId
     * @param {String} processJobDescShort
     * @param {String} processJobDescLong
     * @param {String} processJobComment
     * @param {String} processJobType
     * @param {String} processJobStatus
     * @returns {Promise<Object>}
     */
    PROCESS_JOB_UPDATE(
        processJobUniqueId,
        processJobDescShort,
        processJobDescLong,
        processJobComment,
        processJobType,
        processJobStatus
    ) {
        return this.request('PROCESS_JOB_UPDATE', feature.general, '', {
            processJobUniqueId,
            processJobDescShort,
            processJobDescLong,
            processJobComment,
            processJobType,
            processJobStatus
        });
    }

    /**
     * @param {String} processJobUniqueId
     * @returns {Promise<Object>}
     */
    PROCESS_JOB_DELETE(processJobUniqueId) {
        return this.request('PROCESS_JOB_DELETE', feature.general, '', {processJobUniqueId});
    }

    /**
     * @param {Object} filter
     * @param {Object} order
     * @param {Number} page
     * @param {Number} itemsPerPage
     * @returns {Promise<Object>}
     */
    PROCESS_JOB_LIST(filter, order, page, itemsPerPage) {
        return this.request('PROCESS_JOB_LIST', feature.general, '', {
            filter,
            order,
            page,
            itemsPerPage
        });
    }

    /**
     * @param {String} processScheduleUniqueId
     * @returns {Promise<Object>}
     */
    PROCESS_SCHEDULE_GET(processScheduleUniqueId) {
        return this.request('PROCESS_JOB_GET', feature.general, '', {processScheduleUniqueId});
    }

    /**
     * @param {Object} filter
     * @param {Object} order
     * @param {Number} page
     * @param {Number} itemsPerPage
     * @returns {Promise<Object>}
     */
    PROCESS_SCHEDULE_LIST(filter, order, page, itemsPerPage) {
        return this.request('PROCESS_SCHEDULE_LIST', feature.general, '', {
            filter,
            order,
            page,
            itemsPerPage
        });
    }

    /**
     * @param {String} ruleUniqueId
     * @param {String} ruleDescShort
     * @param {String} ruleDescLong
     * @param {String} ruleComment
     * @param {String} ruleType
     * @param {String} ruleStatus
     * @param {Object} ruleConfig
     * @returns {Promise<Object>}
     */
    RULE_CREATE(ruleUniqueId, ruleDescShort, ruleDescLong, ruleComment, ruleType, ruleStatus, ruleConfig) {
        return this.request('RULE_CREATE', feature.general, '', {
            ruleUniqueId,
            ruleDescShort,
            ruleDescLong,
            ruleComment,
            ruleType,
            ruleStatus,
            ruleConfig,
        });
    }

    /**
     * @param {String} ruleUniqueId
     * @returns {Promise<Object>}
     */
    RULE_GET(ruleUniqueId) {
        return this.request('RULE_GET', feature.general, '', {ruleUniqueId});
    }

    /**
     * @param {String} ruleUniqueId
     * @param {String} ruleDescShort
     * @param {String} ruleDescLong
     * @param {String} ruleComment
     * @param {Object} ruleConfig
     * @returns {Promise<Object>}
     */
    RULE_UPDATE(
        ruleUniqueId,
        ruleDescShort,
        ruleDescLong,
        ruleComment,
        ruleConfig
    ) {
        return this.request('RULE_UPDATE', feature.general, '', {
            ruleUniqueId,
            ruleDescShort,
            ruleDescLong,
            ruleComment,
            ruleConfig
        });
    }

    /**
     * @param {String} ruleUniqueId
     * @returns {Promise<Object>}
     */
    RULE_DELETE(ruleUniqueId) {
        return this.request('RULE_DELETE', feature.general, '', ruleUniqueId);
    }

    /**
     * @param {Object} filter
     * @param {Object} order
     * @param {Number} page
     * @param {Number} itemsPerPage
     * @returns {Promise<Object>}
     */
    RULE_LIST(filter, order, page, itemsPerPage) {
        return this.request('RULE_LIST', feature.general, '', {
            filter,
            order,
            page,
            itemsPerPage
        });
    }

    /**
     * @param {String} workflowUniqueId
     * @param {String} workflowDescShort
     * @param {String} workflowDescLong
     * @param {String} workflowComment
     * @param {String} workflowType
     * @param {String} workflowStatus
     * @param {Object} workflowConfig
     * @returns {Promise<Object>}
     */
    WORKFLOW_CREATE(
        workflowUniqueId,
        workflowDescShort,
        workflowDescLong,
        workflowComment,
        workflowType,
        workflowStatus,
        workflowConfig
    ) {
        return this.request('WORKFLOW_CREATE', feature.general, '', {
            workflowUniqueId,
            workflowDescShort,
            workflowDescLong,
            workflowComment,
            workflowType,
            workflowStatus,
            workflowConfig,
        });
    }

    /**
     * @param {String} workflowUniqueId
     * @returns {Promise<Object>}
     */
    WORKFLOW_GET(workflowUniqueId) {
        return this.request('WORKFLOW_GET', feature.general, '', {workflowUniqueId});
    }

    /**
     * @param {String} workflowUniqueId
     * @param {String} workflowDescShort
     * @param {String} workflowDescLong
     * @param {String} workflowComment
     * @param {String} workflowStatus
     * @param {Object} workflowConfig
     * @returns {Promise<Object>}
     */
    WORKFLOW_UPDATE(
        workflowUniqueId,
        workflowDescShort,
        workflowDescLong,
        workflowComment,
        workflowStatus,
        workflowConfig
    ) {
        return this.request('WORKFLOW_UPDATE', feature.general, '', {
            workflowUniqueId,
            workflowDescShort,
            workflowDescLong,
            workflowComment,
            workflowStatus,
            workflowConfig

        });
    }

    /**
     * @param {String} workflowUniqueId
     * @returns {Promise<Object>}
     */
    WORKFLOW_DELETE(workflowUniqueId) {
        return this.request('WORKFLOW_DELETE', feature.general, '', {workflowUniqueId});
    }

    /**
     * @param {Object} filter
     * @param {Object} order
     * @param {Number} page
     * @param {Number} itemsPerPage
     * @returns {Promise<Object>}
     */
    WORKFLOW_LIST(filter, order, page, itemsPerPage) {
        return this.request('WORKFLOW_LIST', feature.general, '', {
            filter,
            order,
            page,
            itemsPerPage
        });
    }

}
