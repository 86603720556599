import Vue from "vue";
import VueI18n from "vue-i18n";
import en_US from "./en-US.json";
import de_DE from "./de-DE.json";
import getCookie from "../lib/get-cookie";

Vue.use(VueI18n);

const messages = {
    "en-US": {
        ...en_US
    },
    "de-DE": {
        ...de_DE
    }
};

let locale = 'en-US';
let localeCookie = getCookie('locale', false);

if (localeCookie && ['en-US', 'de-DE'].includes(localeCookie)) {
    locale = localeCookie;
}

export default new VueI18n({locale, messages});
