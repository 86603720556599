import LiftService from "@/services/lift.service";

const channel = {}
const channels = {
    page: 1,
    itemsPerPage: 10,
    limit: null,
    total: 0,
    list: []
}

const channelModule = {
    namespaced: true,
    state: {channel, channels},
    actions: {
        CREATE({commit}, payload) {
            commit('loading/CHANNEL_CREATE', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.CHANNEL_CREATE(
                    payload.channelDescLong,
                    payload.channelType,
                    payload.serviceProviderServiceUniqueId
                )
                    .then(channel => {
                        commit('populateChannel', channel);
                        commit('loading/CHANNEL_CREATE', false, {root: true});
                        resolve(channel);
                    })
                    .catch(error => {
                        commit('loading/CHANNEL_CREATE', false, {root: true});
                        reject(error);
                    });
            });
        },
        GET({commit}, channelUniqueId) {
            commit('loading/CHANNEL_GET', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.CHANNEL_GET(channelUniqueId)
                    .then(channel => {
                        commit('populateChannel', channel);
                        commit('loading/CHANNEL_GET', false, {root: true});
                        resolve(channel);
                    })
                    .catch(error => {
                        commit('loading/CHANNEL_GET', false, {root: true});
                        reject(error);
                    });
            });
        },
        UPDATE({commit}, payload) {
            commit('loading/CHANNEL_UPDATE', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.CHANNEL_UPDATE(
                    payload.channelUniqueId,
                    payload.channelDescShort,
                    payload.channelDescLong,
                    payload.channelComment,
                    payload.channelType,
                    payload.channelStatus,
                    payload.serviceProviderServiceUniqueId,
                    payload.mboxEmail
                )
                    .then(channel => {
                        commit('populateChannel', channel);
                        commit('loading/CHANNEL_UPDATE', false, {root: true});
                        resolve(channel);
                    })
                    .catch(error => {
                        commit('loading/CHANNEL_UPDATE', false, {root: true});
                        reject(error);
                    });
            });
        },
        DELETE({commit}, channelUniqueId) {
            commit('loading/CHANNEL_DELETE', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.CHANNEL_DELETE(channelUniqueId)
                    .then(channel => {
                        commit('populateChannel', channel);
                        commit('loading/CHANNEL_DELETE', false, {root: true});
                        resolve(channel);
                    })
                    .catch(error => {
                        commit('loading/CHANNEL_DELETE', false, {root: true});
                        reject(error);
                    });
            });
        },
        LIST({commit}, payload) {
            commit('loading/CHANNEL_LIST', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.CHANNEL_LIST(payload.filter, payload.order, payload.page, payload.itemsPerPage)
                    .then(channels => {
                        commit('populateChannels', channels);
                        commit('loading/CHANNEL_LIST', false, {root: true});
                        resolve(channels);
                    })
                    .catch(error => {
                        commit('clearChannels');
                        commit('loading/CHANNEL_LIST', false, {root: true});
                        reject(error);
                    });
            });
        },
    },
    mutations: {
        clearChannels(state) {
            state.channels = {
                page: 1,
                itemsPerPage: 10,
                limit: null,
                total: 0,
                list: []
            }
        },
        populateChannels(state, channels) {
            state.channels = channels;
        },
        populateChannel(state, channel) {
            state.channel = channel;
        }
    }
};

export default channelModule;