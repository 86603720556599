const backdropModule = {
    namespaced: true,
    state: {visible: false},
    actions: {},
    mutations: {
        toggle(state) {
            state.visible = !state.visible;
        },
        hide(state) {
            state.visible = false;
        },
        show(state) {
            state.visible = true;
        }
    }
}

export default backdropModule;