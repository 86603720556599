import i18n from '@/i18n';

const initialState = {
    open: true,
    position: 0,
    width: '224px',
    collapsed: {
        dashboard: false,
        marketing: false,
        automation: false,
        storage: false,
        account: true,
        settings: true,
        security: true,
        addons: true
    }
}

const sidebarModule = {
    namespaced: true,
    state: initialState,
    mutations: {
        toggleSidebar(state) {
            state.open = !state.open;
            if (state.width === '224px') {
                state.width = '45px';
            } else {
                state.width = '224px'
            }
        },
        openSidebar(state, width) {
            state.open = true;
            state.width = width;
        },
        closeSidebar(state, width) {
            state.open = false;
            state.width = width;
        },
        toggleGroup(state, identifier) {
            state.collapsed[identifier] = !state.collapsed[identifier];
        },
        setPosition(state, position) {
            state.position = position;
        }
    },
    getters: {
        content: () => {
            return [
                {
                    identifier: 'dashboard',
                    name: i18n.tc('dashboard'),
                    items: [
                        {
                            name: i18n.tc('overview', 1),
                            route: 'dashboard-overview',
                            icon: 'tachometer-alt'
                        }
                    ]
                },
                {
                    identifier: 'marketing',
                    name: i18n.t('marketing'),
                    items: [
                        {
                            name: i18n.tc('campaign', 2),
                            route: 'marketing-campaigns',
                            icon: 'bullhorn'
                        },
                        {
                            name: i18n.tc('contact', 2),
                            route: 'marketing-contacts',
                            icon: 'address-book'
                        },
                        {
                            name: i18n.tc('segment', 2),
                            route: 'marketing-segments',
                            icon: 'chart-pie'
                        },
                        {
                            name: i18n.tc('channel', 2),
                            route: 'marketing-channels',
                            icon: 'network-wired'
                        },
                    ]
                },
                {
                    identifier: 'automation',
                    name: i18n.tc('automation', 1),
                    items: [
                        {
                            name: i18n.tc('event', 2),
                            route: 'automation-events',
                            icon: 'angle-double-right'
                        },
                        {
                            name: i18n.tc('rule', 2),
                            route: 'automation-rules',
                            icon: 'clipboard-check'
                        },
                        {
                            name: i18n.tc('workflow', 2),
                            route: 'automation-workflows',
                            icon: 'project-diagram'
                        },
                        {
                            name: i18n.tc('process-schedule', 1),
                            route: 'automation-process-schedule',
                            icon: 'clock'
                        },
                        {
                            name: i18n.tc('process-job', 2),
                            route: 'automation-process-jobs',
                            icon: 'tasks'
                        },
                        {
                            name: i18n.tc('queue', 1),
                            route: 'automation-queue',
                            icon: 'sort-amount-down-alt'
                        }
                    ]
                },
                {
                    identifier: 'storage',
                    name: i18n.tc('storage', 1),
                    items: [
                        {
                            name: i18n.tc('library', 1),
                            route: 'storage-library',
                            icon: 'hdd'
                        },
                        {
                            name: i18n.tc('database', 2),
                            route: 'storage-databases',
                            icon: 'database'
                        }
                    ]
                },
                {
                    identifier: 'account',
                    name: i18n.tc('account', 1),
                    items: [
                        {
                            name: i18n.tc('summary', 1),
                            route: 'account-summary',
                            icon: 'newspaper'
                        },
                        {
                            name: i18n.tc('organization', 1),
                            route: 'account-organization',
                            icon: 'building'
                        },
                        {
                            name: i18n.tc('product', 2),
                            route: 'account-products',
                            icon: 'laptop-code'
                        },
                        {
                            name: i18n.tc('billing', 1),
                            route: 'account-billing',
                            icon: 'file-invoice'
                        },
                        {
                            name: i18n.tc('payment-method', 2),
                            route: 'account-payment-methods',
                            icon: 'credit-card'
                        }
                    ]
                },
                {
                    identifier: 'settings',
                    name: i18n.tc('setting', 2),
                    items: [
                        {
                            name: i18n.tc('general'),
                            route: 'settings-general',
                            icon: 'cog'
                        },
                        {
                            name: i18n.t('logging'),
                            route: 'settings-logging',
                            icon: 'file-alt'
                        },
                        {
                            name: i18n.tc('notification', 2),
                            route: 'settings-notifications',
                            icon: 'bell'
                        },
                        {
                            name: i18n.tc('merchant', 2),
                            route: 'settings-merchants',
                            icon: 'store'
                        },
                        {
                            name: i18n.tc('service-provider', 2),
                            route: 'settings-service-providers',
                            icon: 'tools'
                        },
                        {
                            name: i18n.tc('trash-bin', 1),
                            route: 'settings-trash-bin',
                            icon: 'trash'
                        },
                    ]
                },
                {
                    identifier: 'security',
                    name: i18n.tc('security', 1),
                    items: [
                        {
                            name: i18n.tc('user', 2),
                            route: 'security-users',
                            icon: 'users'
                        }
                    ]
                },
                {
                    identifier: 'addons',
                    name: i18n.tc('addons'),
                    items: [
                        {
                            name: i18n.tc('overview', 1),
                            route: 'addons-overview',
                            icon: 'puzzle-piece'
                        },
                        {
                            name: i18n.tc('suite', 2),
                            route: 'addons-suites',
                            icon: 'sitemap'
                        },
                        {
                            name: i18n.tc('linked-service', 2),
                            route: 'addons-linked-services',
                            icon: 'link'
                        },
                        {
                            name: i18n.t('professional-services'),
                            route: 'addons-professional-services',
                            icon: 'hands-helping'
                        },
                        {
                            name: i18n.tc('device', 2),
                            route: 'addons-devices',
                            icon: 'tablet-alt'
                        },
                        {
                            name: i18n.tc('application', 2),
                            route: 'addons-applications',
                            icon: 'window-maximize'
                        },
                    ]
                }
            ]
        }
    }
}

export default sidebarModule;
