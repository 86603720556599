const feature = {
    general: 'SPOTEMOTIONLIFT_GENERAL',
    campaignRegularManual: 'SPOTEMOTIONLIFT_CAMPAIGN_REGULAR_MANUAL',
    campaignRegularImport: 'SPOTEMOTIONLIFT_CAMPAIGN_REGULAR_IMPORT',
    campaignAutoEvent: 'SPOTEMOTIONLIFT_CAMPAIGN_AUTO_EVENT',
    campaignAutoEventSchedule: 'SPOTEMOTIONLIFT_CAMPAIGN_AUTO_EVENT_SCHEDULE',
    campaignAutoEventCustom: 'SPOTEMOTIONLIFT_CAMPAIGN_AUTO_EVENT_CUSTOM',
    campaignAutoWorkflowState: 'SPOTEMOTIONLIFT_CAMPAIGN_AUTO_WORKFLOW_3STATE',
    campaignAutoWorkflowRulebase: 'SPOTEMOTIONLIFT_CAMPAIGN_AUTO_WORKFLOW_RULEBASE',
    campaignAutoWorkflowCustom: 'SPOTEMOTIONLIFT_CAMPAIGN_AUTO_WORKFLOW_CUSTOM',
    campaignIntegratedApi: 'SPOTEMOTIONLIFT_CAMPAIGN_INTEGRATED_API'
}

export default feature;