import LiftService from "@/services/lift.service";

const mediaLibrary = {};

const mediaLibraries = {
    page: 1,
    itemsPerPage: 10,
    limit: null,
    total: 0,
    list: []
}

const mediaLibraryModule = {
    namespaced: true,
    state: {mediaLibrary, mediaLibraries},
    actions: {
        GET({commit}, mediaLibrary) {
            commit('loading/MEDIA_LIBRARY_GET', true, {root: true});
            commit('populateMediaLibrary', mediaLibrary);
            commit('loading/MEDIA_LIBRARY_GET', false, {root: true});
        },
        LIST({commit}, payload) {
            commit('loading/MEDIA_LIBRARY_LIST', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.MEDIA_LIBRARY_LIST(
                    payload.filter,
                    payload.order,
                    payload.page,
                    payload.itemsPerPage
                )
                    .then(mediaLibraries => {
                        commit('loading/MEDIA_LIBRARY_LIST', false, {root: true});
                        commit('populateMediaLibraries', mediaLibraries);
                        resolve(mediaLibraries);
                    })
                    .catch(error => {
                        commit('clearMediaLibraries');
                        commit('loading/MEDIA_LIBRARY_LIST', false, {root: true});
                        reject(error);
                    });
            });
        },
    },
    mutations: {
        clearMediaLibrary(state) {
            state.mediaLibrary = {};
        },
        populateMediaLibrary(state, mediaLibrary) {
            state.mediaLibrary = mediaLibrary;
        },
        clearMediaLibraries(state) {
            state.mediaLibraries = {
                page: 1,
                itemsPerPage: 10,
                limit: null,
                total: 0,
                list: []
            }
        },
        populateMediaLibraries(state, mediaLibraries) {
            state.mediaLibraries = mediaLibraries;
        },
    }
};

export default mediaLibraryModule;
