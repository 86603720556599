import LiftService from "@/services/lift.service";

const segment = {};
const segments = {
    page: 1,
    itemsPerPage: 10,
    limit: null,
    total: 0,
    list: []
}

const segmentModule = {
    namespaced: true,
    state: {segment, segments},
    actions: {
        CREATE({commit}, payload) {
            commit('loading/SEGMENT_CREATE', true, {root: true});
            commit('clearSegment');
            return new Promise((resolve, reject) => {
                LiftService.SEGMENT_CREATE(payload.segmentDescShort, payload.segmentDescLong, payload.segmentComment)
                    .then(segments => {
                        commit('populateSegments', segments);
                        commit('loading/SEGMENT_CREATE', false, {root: true});
                        resolve(segments);
                    })
                    .catch(error => {
                        commit('loading/SEGMENT_CREATE', false, {root: true});
                        reject(error);
                    });
            });
        },
        GET({commit}, data) {
            commit('loading/SEGMENT_GET', true, {root: true});
            commit('clearSegment');
            return new Promise((resolve, reject) => {
                LiftService.SEGMENT_GET(data)
                    .then(segment => {
                        commit('populateSegment', segment);
                        commit('loading/SEGMENT_GET', false, {root: true});
                        resolve(segment);
                    })
                    .catch(error => {
                        commit('loading/SEGMENT_GET', false, {root: true});
                        reject(error);
                    });
            });
        },
        UPDATE({commit}, payload) {
            commit('loading/SEGMENT_UPDATE', true, {root: true});
            commit('clearSegment');
            return new Promise((resolve, reject) => {
                LiftService.SEGMENT_UPDATE(
                    payload.segmentUniqueId,
                    payload.segmentDescShort,
                    payload.segmentDescLong,
                    payload.segmentComment
                )
                    .then(segment => {
                        commit('populateSegment', segment);
                        commit('loading/SEGMENT_UPDATE', false, {root: true});
                        resolve(segment);
                    })
                    .catch(error => {
                        commit('loading/SEGMENT_UPDATE', false, {root: true});
                        reject(error);
                    });
            });
        },
        DELETE({commit}, segmentUniqueId) {
            commit('loading/SEGMENT_DELETE', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.SEGMENT_DELETE(segmentUniqueId)
                    .then(() => {
                        commit('loading/SEGMENT_DELETE', false, {root: true});
                        resolve();
                    })
                    .catch(error => {
                        commit('loading/SEGMENT_DELETE', false, {root: true});
                        reject(error);
                    });
            });
        },
        LIST({commit}, payload) {
            commit('loading/SEGMENT_LIST', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.SEGMENT_LIST(
                    payload.filter,
                    payload.order,
                    payload.page,
                    payload.itemsPerPage,
                    payload.limit
                )
                    .then(segments => {
                        commit('populateSegments', segments);
                        commit('loading/SEGMENT_LIST', false, {root: true});
                        resolve(segments);
                    })
                    .catch(error => {
                        commit('clearSegments');
                        commit('loading/SEGMENT_LIST', false, {root: true});
                        reject(error);
                    });
            });
        },
        ADD_CONTACT({commit}, payload) {
            commit('loading/SEGMENT_ADD_CONTACT', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.SEGMENT_ADD_CONTACT(
                    payload.segmentUniqueId,
                    payload.contactUniqueId
                )
                    .then(() => {
                        commit('loading/SEGMENT_ADD_CONTACT', false, {root: true});
                        resolve();
                    })
                    .catch(error => {
                        commit('loading/SEGMENT_ADD_CONTACT', false, {root: true});
                        reject(error);
                    });
            });
        },
        REMOVE_CONTACT({commit}, payload) {
            commit('loading/SEGMENT_REMOVE_CONTACT', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.SEGMENT_REMOVE_CONTACT(
                    payload.segmentUniqueId,
                    payload.contactUniqueId
                )
                    .then(() => {
                        commit('loading/SEGMENT_REMOVE_CONTACT', false, {root: true});
                        resolve();
                    })
                    .catch(error => {
                        commit('loading/SEGMENT_REMOVE_CONTACT', false, {root: true});
                        reject(error);
                    });
            });
        }
    },
    mutations: {
        clearSegments(state) {
            state.segments = {
                page: 1,
                itemsPerPage: 10,
                limit: null,
                total: 0,
                list: []
            }
        },
        populateSegments(state, segments) {
            state.segments = segments
        },
        clearSegment(state) {
            state.segment = {};
        },
        populateSegment(state, segment) {
            state.segment = segment
        },
    }
};

export default segmentModule;