import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import '@/assets/scss/theme.scss';
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import i18n from '@/i18n';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from "@fortawesome/fontawesome-svg-core";
import Gravatar from 'vue-gravatar';
import moment from "moment";
import VuePapaParse from 'vue-papa-parse';
import Vuelidate from 'vuelidate';
import VueGtagPlugin from "vue-gtag";
import {Plugin} from 'vue-fragment'
import {
    faAd,
    faAddressBook,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleLeft,
    faAngleRight,
    faArchive,
    faArrowLeft,
    faBars,
    faBell as faBellSolid,
    faBuilding,
    faBullhorn,
    faCalendarCheck,
    faCaretDown,
    faCaretUp,
    faChartPie,
    faCheck,
    faCheckCircle,
    faCheckSquare,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircle,
    faCircleNotch,
    faClipboardCheck,
    faClock,
    faCloudDownloadAlt,
    faCog,
    faCogs,
    faCompressArrowsAlt,
    faCopy,
    faCreditCard,
    faDatabase,
    faEdit,
    faEllipsisH,
    faEnvelope,
    faEnvelopeOpen,
    faEnvelopeOpenText,
    faExchangeAlt,
    faExclamationCircle,
    faExclamationTriangle,
    faExternalLinkAlt,
    faExternalLinkSquareAlt,
    faEye,
    faEyeSlash,
    faFileAlt,
    faFileCsv,
    faFileExport,
    faFileImport,
    faFileInvoice,
    faFileUpload,
    faHandsHelping,
    faHdd,
    faInfo,
    faInfoCircle,
    faLanguage,
    faLaptopCode,
    faLink,
    faListUl,
    faMailBulk,
    faNetworkWired,
    faNewspaper,
    faPaperPlane,
    faPen,
    faPhoneVolume,
    faPlus,
    faPlusSquare,
    faPollH,
    faProjectDiagram,
    faPuzzlePiece,
    faRecycle,
    faSearch,
    faSearchDollar,
    faServer,
    faSitemap,
    faSort,
    faSortAmountDownAlt,
    faSortDown,
    faSortUp,
    faStickyNote,
    faStore,
    faSyncAlt,
    faTabletAlt,
    faTachometerAlt,
    faTasks,
    faThumbsUp,
    faTimes,
    faToggleOn,
    faTools,
    faTrash,
    faUser,
    faUserCog,
    faUsers,
    faWindowMaximize,
    faWrench,
    faDownload
} from "@fortawesome/free-solid-svg-icons";

import {
    faBell as faBellRegular,
    faQuestionCircle,
    faSquare,
    faStar,
    faTrashAlt
} from "@fortawesome/free-regular-svg-icons";

library.add(
    faLanguage,
    faBellSolid,
    faBellRegular,
    faQuestionCircle,
    faSearch,
    faTachometerAlt,
    faCircle,
    faAngleLeft,
    faAngleRight,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faPen,
    faChevronDown,
    faChevronUp,
    faChevronLeft,
    faChevronRight,
    faCaretDown,
    faCaretUp,
    faTimes,
    faCheck,
    faEye,
    faCog,
    faStar,
    faTrashAlt,
    faFileAlt,
    faFileImport,
    faFileExport,
    faWrench,
    faMailBulk,
    faUser,
    faUsers,
    faUserCog,
    faBuilding,
    faListUl,
    faFileInvoice,
    faCreditCard,
    faSitemap,
    faLink,
    faCogs,
    faTabletAlt,
    faWindowMaximize,
    faBars,
    faEllipsisH,
    faAd,
    faEnvelope,
    faSearchDollar,
    faPollH,
    faChevronRight,
    faBullhorn,
    faEnvelopeOpenText,
    faExclamationTriangle,
    faCircleNotch,
    faArrowLeft,
    faEnvelopeOpen,
    faCheckCircle,
    faExclamationCircle,
    faExternalLinkSquareAlt,
    faTrash,
    faFileUpload,
    faProjectDiagram,
    faSortAmountDownAlt,
    faClock,
    faNewspaper,
    faLaptopCode,
    faStore,
    faServer,
    faExchangeAlt,
    faPuzzlePiece,
    faThumbsUp,
    faEllipsisH,
    faToggleOn,
    faAddressBook,
    faChartPie,
    faPlus,
    faCalendarCheck,
    faStickyNote,
    faNetworkWired,
    faCompressArrowsAlt,
    faTools,
    faSquare,
    faCheckSquare,
    faCloudDownloadAlt,
    faFileCsv,
    faSyncAlt,
    faInfo,
    faInfoCircle,
    faEdit,
    faPaperPlane,
    faPhoneVolume,
    faHandsHelping,
    faExternalLinkAlt,
    faArchive,
    faHdd,
    faTasks,
    faDatabase,
    faClipboardCheck,
    faPlusSquare,
    faRecycle,
    faCopy,
    faPaperPlane,
    faEyeSlash,
    faSortUp,
    faSortDown,
    faSort,
    faDownload,
);

Vue.config.productionTip = false

Vue.use(VuePapaParse);
Vue.use(Vuelidate);
Vue.use(Plugin)
Vue.use(VueGtagPlugin, {config: {id: process.env.VUE_APP_GTAG_ID}});
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('v-gravatar', Gravatar);
Vue.filter('date', date => moment(date).format('DD.MM.YYYY'));
Vue.filter('timestamp', date => moment(date).format('DD.MM.YYYY HH:mm:ss'));
Vue.filter('bytes', (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
});

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
    created() {
        const html = document.documentElement;
        html.setAttribute('lang', i18n.locale.substring(0, 2));
        window.addEventListener("resize", this.setSidebar);
        this.setSidebar();
    },
    methods: {
        setSidebar() {
            if (window.innerWidth < 768) {
                this.$store.commit('sidebar/closeSidebar', '0px')
            } else if (window.innerWidth < 1200) {
                this.$store.commit('sidebar/closeSidebar', '45px')
            } else {
                this.$store.commit('sidebar/openSidebar', '224px')
            }
        }
    }
}).$mount('#app')
