import getCookie from './get-cookie';

export default function getBearerAuth() {
    const user = getCookie('user')

    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.token
        },
        maxContentLength: Infinity,
        maxBodyLength: Infinity
    }
}
