const promotionModule = {
    namespaced: true,
    state: {
        promotions: [
            /*
            {
                description: "Hello! Here is something worth paying attention to. Check out these offerings that will help you run your business even more efficiently.",
                title: "Interactive GEM premium, powered by STINA",
                details: "Get more features that will help you run your business more efficiently by subscribing to our Interactive GEM premium service",
                url: "#"
            }
            */
        ]
    },
    actions: {
        get() {
            // get promotions from service and commit them
        }
    },
    mutations: {
        setPromotions(state, promotions) {
            state.promotions = promotions;
        },
        removePromotion(state, index) {
            state.promotions.splice(index, 1);
        }
    }
}

export default promotionModule;