import Vue from "vue";
import Vuex from "vuex";
import backdropModule from "../modules/backdrop.module";
import sidebarModule from "../modules/sidebar.module";
import toastModule from "../modules/toast.module";
import promotionModule from "../modules/promotion.module";
import notificationModule from "../modules/notification.module";
import loadingModule from "../modules/loading.module";
import userModule from "../modules/user.module";
import campaignModule from "../modules/campaign.module";
import eventModule from "../modules/event.module";
import channelModule from "../modules/channel.module";
import serviceProviderServiceModule from "../modules/service-provider-service.module";
import contactModule from "../modules/contact.module";
import segmentModule from "../modules/segment.module";
import mediaLibraryModule from "../modules/media-library.module";
import mediaObjectModule from "../modules/media-object.module";
import processJobModule from "../modules/process-job.module";
import processScheduleModule from "../modules/process-schedule.module";
import ruleModule from "../modules/rule.module";
import workflowModule from "../modules/workflow.module";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        // Application modules
        backdrop: backdropModule,
        sidebar: sidebarModule,
        toast: toastModule,
        promotion: promotionModule,
        notification: notificationModule,
        loading: loadingModule,
        // API modules
        USER: userModule,
        CAMPAIGN: campaignModule,
        EVENT: eventModule,
        CHANNEL: channelModule,
        SERVICE_PROVIDER_SERVICE: serviceProviderServiceModule,
        CONTACT: contactModule,
        SEGMENT: segmentModule,
        MEDIA_LIBRARY: mediaLibraryModule,
        MEDIA_OBJECT: mediaObjectModule,
        PROCESS_JOB: processJobModule,
        PROCESS_SCHEDULE: processScheduleModule,
        RULE: ruleModule,
        WORKFLOW: workflowModule,
    }
})
