import createInfoObject from "./create-info-object";
import axios from "axios";
import getBearerAuth from "./get-bearer-auth";

class Service {
    constructor() {
        this.nodeUniqueId = process.env.VUE_APP_NODE_UNIQUE_ID;
        this.auth = {
            username: process.env.VUE_APP_API_KEY,
            password: process.env.VUE_APP_API_SECRET
        }
        this.url = process.env.VUE_APP_SERVICE_URL + '/' + process.env.VUE_APP_SERVICE_PORTFOLIO + '/info/' + process.env.VUE_APP_SERVICE_VERSION;
    }

    /**
     * @param {String} type
     * @param {String} feature
     * @param {String} mode
     * @param {Object} payload
     * @param {String} context
     * @returns {Promise<Object>}
     */
    request(type, feature, mode, payload, context = 'USER') {
        return new Promise((resolve, reject) => {
            const requestData = createInfoObject(type, feature, mode, payload);

            let auth;

            switch (context) {
                case 'USER':
                    auth = getBearerAuth();
                    break;
                case 'PUBLIC':
                    auth = {
                        auth: this.auth
                    };
                    break;
            }

            axios.post(this.url, requestData, auth)
                .then(response => resolve(response.data.data))
                .catch(error => reject(error));
        });
    }
}

export default Service;