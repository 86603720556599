const state = {
    USER_LOGIN: false,
    USER_LOGOUT: false,
    USER_LOST_PASSWORD: false,
    USER_RECOVER_PASSWORD: false,
    USER_GET_PROFILE: false,
    USER_UPDATE_PROFILE: false,
    USER_UPDATE_PASSWORD: false,
    CAMPAIGN_CREATE: false,
    CAMPAIGN_GET: false,
    CAMPAIGN_UPDATE: false,
    CAMPAIGN_LIST: false,
    CAMPAIGN_UPDATE_CHANNEL: false,
    CAMPAIGN_GET_RECIPIENT_CONFIG: false,
    CAMPAIGN_PREVIEW_RECIPIENT: false,
    CAMPAIGN_SAVE_RECIPIENTS_CONFIG: false,
    CAMPAIGN_LIST_TRIGGER: false,
    EVENT_TRIGGER_CAMPAIGN: false,
    CHANNEL_CREATE: false,
    CHANNEL_GET: false,
    CHANNEL_UPDATE: false,
    CHANNEL_DELETE: false,
    CHANNEL_LIST: false,
    SERVICE_PROVIDER_SERVICE_LIST: false,
    CONTACT_CREATE: false,
    CONTACT_GET: false,
    CONTACT_UPDATE: false,
    CONTACT_DELETE: false,
    CONTACT_LIST: false,
    SEGMENT_CREATE: false,
    SEGMENT_GET: false,
    SEGMENT_UPDATE: false,
    SEGMENT_DELETE: false,
    SEGMENT_LIST: false,
    SEGMENT_ADD_CONTACT: false,
    SEGMENT_REMOVE_CONTACT: false,
    MEDIA_LIBRARY_GET: false,
    MEDIA_LIBRARY_LIST: false,
    MEDIA_OBJECT_CREATE: false,
    MEDIA_OBJECT_GET: false,
    MEDIA_OBJECT_UPDATE: false,
    MEDIA_OBJECT_DELETE: false,
    MEDIA_OBJECT_LIST: false,
    PROCESS_JOB_CREATE: false,
    PROCESS_JOB_GET: false,
    PROCESS_JOB_UPDATE: false,
    PROCESS_JOB_DELETE: false,
    PROCESS_JOB_LIST: false,
    PROCESS_SCHEDULE_GET: false,
    PROCESS_SCHEDULE_LIST: false,
    RULE_CREATE: false,
    RULE_GET: false,
    RULE_UPDATE: false,
    RULE_DELETE: false,
    RULE_LIST: false,
    WORKFLOW_CREATE: false,
    WORKFLOW_GET: false,
    WORKFLOW_UPDATE: false,
    WORKFLOW_DELETE: false,
    WORKFLOW_LIST: false,
}

const loadingModule = {
    namespaced: true,
    state,
    actions: {},
    mutations: {
        USER_LOGIN(state, loading) {
            state.USER_LOGIN = loading;
        },
        USER_LOGOUT(state, loading) {
            state.USER_LOGOUT = loading;
        },
        USER_LOST_PASSWORD(state, loading) {
            state.USER_LOST_PASSWORD = loading;
        },
        USER_RECOVER_PASSWORD(state, loading) {
            state.USER_RECOVER_PASSWORD = loading;
        },
        USER_GET_PROFILE(state, loading) {
            state.USER_GET_PROFILE = loading;
        },
        USER_UPDATE_PROFILE(state, loading) {
            state.USER_UPDATE_PROFILE = loading;
        },
        USER_UPDATE_PASSWORD(state, loading) {
            state.USER_UPDATE_PASSWORD = loading;
        },
        CAMPAIGN_CREATE(state, loading) {
            state.CAMPAIGN_CREATE = loading;
        },
        CAMPAIGN_GET(state, loading) {
            state.CAMPAIGN_GET = loading;
        },
        CAMPAIGN_UPDATE(state, loading) {
            state.CAMPAIGN_UPDATE = loading;
        },
        CAMPAIGN_LIST(state, loading) {
            state.CAMPAIGN_LIST = loading;
        },
        CAMPAIGN_UPDATE_CHANNEL(state, loading) {
            state.CAMPAIGN_UPDATE_CHANNEL = loading;
        },
        CAMPAIGN_GET_RECIPIENT_CONFIG(state, loading) {
            state.CAMPAIGN_GET_RECIPIENT_CONFIG = loading;
        },
        CAMPAIGN_PREVIEW_RECIPIENT(state, loading) {
            state.CAMPAIGN_PREVIEW_RECIPIENT = loading;
        },
        CAMPAIGN_SAVE_RECIPIENTS_CONFIG(state, loading) {
            state.CAMPAIGN_SAVE_RECIPIENTS_CONFIG = loading;
        },
        CAMPAIGN_LIST_TRIGGER(state, loading) {
            state.CAMPAIGN_LIST_TRIGGER = loading;
        },
        EVENT_TRIGGER_CAMPAIGN(state, loading) {
            state.EVENT_TRIGGER_CAMPAIGN = loading;
        },
        CHANNEL_CREATE(state, loading) {
            state.CHANNEL_CREATE = loading;
        },
        CHANNEL_GET(state, loading) {
            state.CHANNEL_GET = loading;
        },
        CHANNEL_UPDATE(state, loading) {
            state.CHANNEL_UPDATE = loading;
        },
        CHANNEL_DELETE(state, loading) {
            state.CHANNEL_DELETE = loading;
        },
        CHANNEL_LIST(state, loading) {
            state.CHANNEL_LIST = loading;
        },
        SERVICE_PROVIDER_SERVICE_LIST(state, loading) {
            state.SERVICE_PROVIDER_SERVICE_LIST = loading;
        },
        CONTACT_CREATE(state, loading) {
            state.CONTACT_CREATE = loading;
        },
        CONTACT_GET(state, loading) {
            state.CONTACT_GET = loading;
        },
        CONTACT_UPDATE(state, loading) {
            state.CONTACT_UPDATE = loading;
        },
        CONTACT_DELETE(state, loading) {
            state.CONTACT_DELETE = loading;
        },
        CONTACT_LIST(state, loading) {
            state.CONTACT_LIST = loading;
        },
        SEGMENT_CREATE(state, loading) {
            state.SEGMENT_CREATE = loading;
        },
        SEGMENT_GET(state, loading) {
            state.SEGMENT_GET = loading;
        },
        SEGMENT_UPDATE(state, loading) {
            state.SEGMENT_UPDATE = loading;
        },
        SEGMENT_DELETE(state, loading) {
            state.SEGMENT_DELETE = loading;
        },
        SEGMENT_LIST(state, loading) {
            state.SEGMENT_LIST = loading;
        },
        SEGMENT_ADD_CONTACT(state, loading) {
            state.SEGMENT_ADD_CONTACT = loading;
        },
        SEGMENT_REMOVE_CONTACT(state, loading) {
            state.SEGMENT_REMOVE_CONTACT = loading;
        },
        MEDIA_LIBRARY_GET(state, loading) {
            state.MEDIA_LIBRARY_GET = loading;
        },
        MEDIA_LIBRARY_LIST(state, loading) {
            state.MEDIA_LIBRARY_LIST = loading;
        },
        MEDIA_OBJECT_CREATE(state, loading) {
            state.MEDIA_OBJECT_CREATE = loading;
        },
        MEDIA_OBJECT_GET(state, loading) {
            state.MEDIA_OBJECT_GET = loading;
        },
        MEDIA_OBJECT_UPDATE(state, loading) {
            state.MEDIA_OBJECT_UPDATE = loading;
        },
        MEDIA_OBJECT_DELETE(state, loading) {
            state.MEDIA_OBJECT_DELETE = loading;
        },
        MEDIA_OBJECT_LIST(state, loading) {
            state.MEDIA_OBJECT_LIST = loading;
        },
        PROCESS_JOB_CREATE(state, loading) {
            state.PROCESS_JOB_CREATE = loading;
        },
        PROCESS_JOB_GET(state, loading) {
            state.PROCESS_JOB_GET = loading;
        },
        PROCESS_JOB_UPDATE(state, loading) {
            state.PROCESS_JOB_UPDATE = loading;
        },
        PROCESS_JOB_DELETE(state, loading) {
            state.PROCESS_JOB_DELETE = loading;
        },
        PROCESS_JOB_LIST(state, loading) {
            state.PROCESS_JOB_LIST = loading;
        },
        PROCESS_SCHEDULE_GET(state, loading) {
            state.PROCESS_SCHEDULE_GET = loading;
        },
        PROCESS_SCHEDULE_LIST(state, loading) {
            state.PROCESS_SCHEDULE_LIST = loading;
        },
        RULE_CREATE(state, loading) {
            state.RULE_CREATE = loading;
        },
        RULE_GET(state, loading) {
            state.RULE_GET = loading;
        },
        RULE_UPDATE(state, loading) {
            state.RULE_UPDATE = loading;
        },
        RULE_DELETE(state, loading) {
            state.RULE_DELETE = loading;
        },
        RULE_LIST(state, loading) {
            state.RULE_LIST = loading;
        },
        WORKFLOW_CREATE(state, loading) {
            state.WORKFLOW_CREATE = loading;
        },
        WORKFLOW_GET(state, loading) {
            state.WORKFLOW_GET = loading;
        },
        WORKFLOW_UPDATE(state, loading) {
            state.WORKFLOW_UPDATE = loading;
        },
        WORKFLOW_DELETE(state, loading) {
            state.WORKFLOW_DELETE = loading;
        },
        WORKFLOW_LIST(state, loading) {
            state.WORKFLOW_LIST = loading;
        },
    }
}

export default loadingModule;
