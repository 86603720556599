const notificationModule = {
    namespaced: true,
    state: {
        notifications: [
            /*
            {
                title: "Advanced monitoring",
                message: "MySQL memory usage is back to normal",
                date: "2020-12-04",
                unread: false,
                favorite: false,
                critical: false
            },
            {
                title: "Advanced monitoring",
                message: "MySQL memory usage is back to normal",
                date: "2020-12-03",
                unread: false,
                favorite: false,
                critical: true
            },
            {
                title: "Advanced monitoring",
                message: "The threshold of MySQL memory usage has been exceeded",
                date: "2020-12-01",
                unread: true,
                favorite: false,
                critical: true
            }
            */
        ]
    },
    actions: {
        get() {
            // get notifications from service and commit them
        }
    },
    mutations: {
        setAlerts(state, notifications) {
            state.notifications = notifications;
        }
    }
}

export default notificationModule;
