import LiftService from "@/services/lift.service";

const eventModule = {
    namespaced: true,
    state: {},
    actions: {
        TRIGGER_CAMPAIGN({commit}, payload) {
            commit('loading/EVENT_TRIGGER_CAMPAIGN', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.EVENT_TRIGGER_CAMPAIGN(
                    payload.campaignUniqueId,
                    payload.channelUniqueId
                )
                    .then(response => {
                        commit('loading/EVENT_TRIGGER_CAMPAIGN', false, {root: true});
                        resolve(response);
                    })
                    .catch(error => {
                        commit('loading/EVENT_TRIGGER_CAMPAIGN', false, {root: true});
                        reject(error);
                    });
            });
        }
    }
};

export default eventModule;
