import LiftService from "@/services/lift.service";

const mediaObject = {}
const mediaObjects = {
    page: 1,
    itemsPerPage: 10,
    limit: null,
    total: 0,
    list: []
}

const mediaObjectModule = {
    namespaced: true,
    state: {mediaObject, mediaObjects},
    actions: {
        CREATE({commit}, payload) {
            commit('loading/MEDIA_OBJECT_CREATE', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.MEDIA_OBJECT_CREATE(
                    payload.mediaObjectUniqueId,
                    payload.mediaObjectDescShort,
                    payload.mediaObjectDescLong,
                    payload.mediaObjectComment,
                    payload.mediaLibraryUniqueId,
                    payload.mediaObjectType,
                    payload.mediaObjectStatus,
                    payload.mediaProperties
                )
                    .then(mediaObject => {
                        commit('loading/MEDIA_OBJECT_CREATE', false, {root: true});
                        commit('populateMediaObject', mediaObject);
                        resolve(mediaObject);
                    })
                    .catch(error => {
                        commit('loading/MEDIA_OBJECT_CREATE', false, {root: true});
                        reject(error);
                    });
            });
        },
        GET({commit}, mediaObjectUniqueId) {
            commit('loading/MEDIA_OBJECT_GET', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.MEDIA_OBJECT_GET(mediaObjectUniqueId)
                    .then(mediaObject => {
                        commit('loading/MEDIA_OBJECT_GET', false, {root: true});
                        commit('populateMediaObject', mediaObject);
                        resolve(mediaObject);
                    })
                    .catch(error => {
                        commit('loading/MEDIA_OBJECT_GET', false, {root: true});
                        reject(error);
                    });
            });
        },
        UPDATE({commit}, payload) {
            commit('loading/MEDIA_OBJECT_UPDATE', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.MEDIA_OBJECT_UPDATE(
                    payload.mediaObjectUniqueId,
                    payload.mediaObjectDescShort,
                    payload.mediaObjectDescLong,
                    payload.mediaObjectComment,
                    payload.mediaObjectType,
                    payload.mediaObjectStatus
                )
                    .then(mediaObject => {
                        commit('loading/MEDIA_OBJECT_UPDATE', false, {root: true});
                        commit('populateMediaObject', mediaObject);
                        resolve(mediaObject);
                    })
                    .catch(error => {
                        commit('loading/MEDIA_OBJECT_UPDATE', false, {root: true});
                        reject(error);
                    });
            });
        },
        DELETE({commit}, mediaObjectUniqueId) {
            commit('loading/MEDIA_OBJECT_DELETE', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.MEDIA_OBJECT_DELETE(mediaObjectUniqueId)
                    .then(mediaObject => {
                        commit('loading/MEDIA_OBJECT_DELETE', false, {root: true});
                        commit('populateMediaObject', mediaObject);
                        resolve(mediaObject);
                    })
                    .catch(error => {
                        commit('loading/MEDIA_OBJECT_DELETE', false, {root: true});
                        reject(error);
                    });
            });
        },
        LIST({commit}, payload) {
            commit('loading/MEDIA_OBJECT_LIST', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.MEDIA_OBJECT_LIST(
                    payload.filter,
                    payload.order,
                    payload.page,
                    payload.itemsPerPage
                )
                    .then(mediaObjects => {
                        commit('loading/MEDIA_OBJECT_LIST', false, {root: true});
                        commit('populateMediaObjects', mediaObjects);
                        resolve(mediaObjects);
                    })
                    .catch(error => {
                        commit('clearMediaObjects');
                        commit('loading/MEDIA_OBJECT_LIST', false, {root: true});
                        reject(error);
                    });
            });
        },
    },
    mutations: {
        clearMediaObjects(state) {
            state.mediaObjects = {
                page: 1,
                itemsPerPage: 10,
                limit: null,
                total: 0,
                list: []
            }
        },
        populateMediaObject(state, mediaObject) {
            state.mediaObject = mediaObject;
        },
        populateMediaObjects(state, mediaObjects) {
            state.mediaObjects = mediaObjects;
        },
    }
};

export default mediaObjectModule;
