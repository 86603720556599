import LiftService from "../services/lift.service";

const campaign = {}
const campaigns = {
    page: 1,
    itemsPerPage: 10,
    limit: null,
    total: 0,
    list: []
}
const temporaryCampaign = {}
const campaignUsers = {
    page: 1,
    itemsPerPage: 10,
    limit: null,
    total: 0,
    list: []
}
const recipientConfig = {}
const recipients = {
    list: [],
    attributes: [],
    summary: {}
}

const campaignModule = {
    namespaced: true,
    state: {campaign, campaigns, temporaryCampaign, recipientConfig, campaignUsers, recipients},
    actions: {
        LIST({commit}, payload) {
            commit('loading/CAMPAIGN_LIST', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.CAMPAIGN_LIST(
                    payload.filter,
                    payload.order,
                    payload.page,
                    payload.itemsPerPage,
                    payload.limit
                )
                    .then(campaigns => {
                        commit('populateCampaigns', campaigns);
                        commit('loading/CAMPAIGN_LIST', false, {root: true});
                        resolve(campaigns);
                    })
                    .catch(error => {
                        commit('clearCampaigns');
                        commit('loading/CAMPAIGN_LIST', false, {root: true});
                        reject(error);
                    });
            });
        },
        CREATE({commit}, payload) {
            commit('loading/CAMPAIGN_CREATE', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.CAMPAIGN_CREATE(
                    payload.campaignDescShort,
                    payload.campaignDescLong,
                    payload.campaignComment,
                    payload.campaignType,
                    payload.campaignReuseCampaignUniqueId,
                    payload.inboundChannels,
                    payload.outboundChannels,
                )
                    .then(campaign => {
                        commit('populateCampaign', campaign);
                        commit('loading/CAMPAIGN_CREATE', false, {root: true});
                        resolve(campaign);
                    })
                    .catch(error => {
                        commit('loading/CAMPAIGN_CREATE', false, {root: true});
                        reject(error);
                    });
            });
        },
        GET({commit}, campaignUniqueId) {
            commit('loading/CAMPAIGN_GET', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.CAMPAIGN_GET(campaignUniqueId)
                    .then(campaign => {
                        commit('populateCampaign', campaign)
                        commit('loading/CAMPAIGN_GET', false, {root: true});
                        resolve(campaign);
                    })
                    .catch(error => {
                        commit('loading/CAMPAIGN_GET', false, {root: true});
                        reject(error);
                    });
            });
        },
        GET_TEMPORARY({commit}, campaignUniqueId) {
            commit('loading/CAMPAIGN_GET', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.CAMPAIGN_GET(campaignUniqueId)
                    .then(campaign => {
                        commit('populateTemporaryCampaign', campaign)
                        commit('loading/CAMPAIGN_GET', false, {root: true});
                        resolve(campaign);
                    })
                    .catch(error => {
                        commit('loading/CAMPAIGN_GET', false, {root: true});
                        reject(error);
                    });
            });
        },
        UPDATE({commit}, payload) {
            commit('loading/CAMPAIGN_UPDATE', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.CAMPAIGN_UPDATE(
                    payload.campaignUniqueId,
                    payload.campaignDescShort,
                    payload.campaignDescLong,
                    payload.campaignComment,
                    payload.campaignType,
                    payload.campaignStatus,
                    payload.mboxEmail,
                    payload.inboundChannels,
                    payload.outboundChannels
                )
                    .then(campaign => {
                        commit('populateCampaign', campaign);
                        commit('loading/CAMPAIGN_UPDATE', false, {root: true});
                        resolve(campaign);
                    })
                    .catch(error => {
                        commit('loading/CAMPAIGN_UPDATE', false, {root: true});
                        reject(error);
                    });
            });
        },
        LIST_TRIGGER({commit}, payload) {
            commit('loading/CAMPAIGN_LIST_TRIGGER', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.CAMPAIGN_LIST_TRIGGER(payload.campaignUniqueId, payload.filter)
                    .then(campaignUsers => {
                        commit('populateCampaignUsers', campaignUsers)
                        commit('loading/CAMPAIGN_LIST_TRIGGER', false, {root: true});
                        resolve(campaignUsers);
                    })
                    .catch(error => {
                        commit('loading/CAMPAIGN_LIST_TRIGGER', false, {root: true});
                        reject(error);
                    });
            });
        },
        UPDATE_CHANNEL({commit}, payload) {
            commit('loading/CAMPAIGN_UPDATE_CHANNEL', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.CAMPAIGN_UPDATE_CHANNEL(
                    payload.campaignUniqueId,
                    payload.channelUniqueId,
                    payload.saveExternalCampaign,
                    payload.configData,
                )
                    .then(campaign => {
                        commit('populateCampaign', campaign);
                        commit('loading/CAMPAIGN_UPDATE_CHANNEL', false, {root: true});
                        resolve(campaign);
                    })
                    .catch(error => {
                        commit('loading/CAMPAIGN_UPDATE_CHANNEL', false, {root: true});
                        reject(error);
                    });
            });
        },
        SAVE_RECIPIENT_CONFIG({commit}, payload) {
            commit('loading/CAMPAIGN_SAVE_RECIPIENTS_CONFIG', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.CAMPAIGN_SAVE_RECIPIENT_CONFIG(
                    payload.campaignUniqueId,
                    payload.sourceType,
                    payload.sourceName,
                    payload.contactUniqueIds,
                    payload.segmentUniqueIds,
                    payload.ruleUniqueId,
                    payload.importConfig
                )
                    .then(() => {
                        commit('loading/CAMPAIGN_SAVE_RECIPIENTS_CONFIG', false, {root: true});
                        resolve()
                    })
                    .catch(error => {
                        commit('loading/CAMPAIGN_SAVE_RECIPIENTS_CONFIG', false, {root: true});
                        reject(error);
                    });
            });
        },
        GET_RECIPIENT_CONFIG({commit}, campaignUniqueId) {
            commit('loading/CAMPAIGN_GET_RECIPIENT_CONFIG', true, {root: true});
            commit('clearRecipientConfig');
            return new Promise((resolve, reject) => {
                LiftService.CAMPAIGN_GET_RECIPIENT_CONFIG(campaignUniqueId)
                    .then(recipientConfig => {
                        commit('populateRecipientConfig', recipientConfig)
                        commit('loading/CAMPAIGN_GET_RECIPIENT_CONFIG', false, {root: true});
                        resolve(recipientConfig)
                    })
                    .catch(error => {
                        commit('loading/CAMPAIGN_GET_RECIPIENT_CONFIG', false, {root: true});
                        reject(error);
                    });
            });
        },
        PREVIEW_RECIPIENT({commit}, payload) {
            return new Promise((resolve, reject) => {
                LiftService.CAMPAIGN_PREVIEW_RECIPIENT(payload.campaignUniqueId, payload.limit)
                    .then(recipients => {
                        commit('populateRecipients', recipients);
                        commit('loading/CAMPAIGN_PREVIEW_RECIPIENT', false, {root: true});
                        resolve()
                    })
                    .catch(error => {
                        commit('loading/CAMPAIGN_PREVIEW_RECIPIENT', false, {root: true});
                        reject(error);
                    });
            });
        }
    },
    mutations: {
        clearCampaigns(state) {
            state.campaigns = {
                page: 1,
                itemsPerPage: 10,
                limit: null,
                total: 0,
                list: []
            }
        },
        populateCampaigns(state, campaigns) {
            state.campaigns = campaigns;
        },
        populateCampaign(state, campaign) {
            state.campaign = campaign;
        },
        populateTemporaryCampaign(state, campaign) {
            state.temporaryCampaign = campaign;
        },
        populateCampaignUsers(state, campaignUsers) {
            state.campaignUsers = campaignUsers;
        },
        clearRecipientConfig(state) {
            state.recipientConfig = {};
        },
        populateRecipientConfig(state, recipientConfig) {
            state.recipientConfig = recipientConfig;
        },
        populateRecipients(state, recipients) {
            state.recipients = recipients
        }
    },
    getters: {
        variables(state) {
            if (!Object.hasOwnProperty.call(state.campaign, 'config')
                || state.campaign.config === null
                || !Object.hasOwnProperty.call(state.campaign.config, 'variables')
            ) {
                return [];
            }

            return state.campaign.config.variables;
        },
        recipientMapping(state) {
            if (!Object.hasOwnProperty.call(state.campaign, 'config')
                || state.campaign.config === null
                || !Object.hasOwnProperty.call(state.campaign.config, 'recipientMapping')
                || state.campaign.config.recipientMapping === null
            ) {
                return {};
            }

            return state.campaign.config.recipientMapping;
        },
        isCampaignFullyConfigured(state) {
            if (state.campaign.config === null) {
                return false;
            } else if (state.campaign.config.recipientMapping === null) {
                return false;
            } else if (state.campaign.config.versioning.recipientMapping !== state.campaign.config.versioning.variables) {
                return false
            } else if (state.campaign.config.versioning.recipientMapping !== state.campaign.config.versioning.recipients) {
                return false;
            } else if (!state.recipientConfig) {
                return false;
            } else if (state.recipientConfig
                && Object.hasOwnProperty.call(state.recipientConfig, 'sourceType')
                && state.recipientConfig.sourceType === 'UNDEFINED'
            ) {
                return false
            }

            return true;
        }
    }
};

export default campaignModule;
