import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import i18n from "../i18n";

Vue.use(VueRouter)

const routes = [
    // Top-level routes
    {
        path: '/',
        name: 'home',
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.home'),
            breadcrumb: [
                {name: i18n.t('home')}
            ]
        }
    },
    {
        path: '/my-profile',
        name: 'my-profile',
        component: () => import(/* webpackChunkName: "profile" */ '../pages/PageMyProfile'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.my-profile'),
            breadcrumb: [
                {name: i18n.tc('my-profile', 1), to: 'my-profile'}
            ]
        }
    },
    {
        path: '/sign-in',
        name: 'sign-in',
        component: () => import(/* webpackChunkName: "sign-in" */ '../pages/PageSignIn'),
        meta: {
            requiresAuth: false,
            title: i18n.t('routes.sign-in'),
            breadcrumb: [
                {name: i18n.t('actions.sign-in')}
            ]
        }
    },
    {
        path: '/password-reset/',
        name: 'password-reset',
        component: () => import(/* webpackChunkName: "password-reset" */ '../pages/PagePasswordReset'),
        meta: {
            requiresAuth: false,
            title: i18n.t('routes.password-reset'),
            breadcrumb: [
                {name: i18n.t('actions.reset-password')}
            ]
        }
    },

    // Dashboard
    {
        path: '/dashboard/overview',
        name: 'dashboard-overview',
        component: () => import(/* webpackChunkName: "dashboard-overview" */ '../pages/dashboard/PageOverview'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.dashboard.overview'),
            breadcrumb: [
                {name: i18n.tc('dashboard', 1)},
                {name: i18n.tc('overview', 1), to: 'dashboard-overview'},
            ]
        }
    },
    // Marketing
    {
        path: '/marketing/campaigns',
        name: 'marketing-campaigns',
        component: () => import(/* webpackChunkName: "campaigns" */ '../pages/marketing/PageCampaigns'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.marketing.campaigns'),
            breadcrumb: [
                {name: i18n.t('marketing')},
                {name: i18n.tc('campaign', 2), to: 'marketing-campaigns'},
            ]
        }
    },
    {
        path: '/marketing/campaigns/:id/settings',
        name: 'marketing-campaign-settings',
        component: () => import(/* webpackChunkName: "marketing-campaign-settings" */ '../pages/marketing/PageCampaignSettings'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.marketing.campaign-settings'),
            breadcrumb: [
                {name: i18n.t('marketing')},
                {name: i18n.tc('campaign', 2), to: 'marketing-campaigns'},
                {name: i18n.tc('campaign-setting', 2)},
            ]
        }
    },
    {
        path: '/marketing/campaigns/:id/log',
        name: 'marketing-campaign-log',
        component: () => import(/* webpackChunkName: "marketing-campaign-log" */ '../pages/marketing/PageCampaignLog'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.marketing.campaign-log'),
            breadcrumb: [
                {name: i18n.t('marketing')},
                {name: i18n.tc('campaign', 2), to: 'marketing-campaigns'},
                {name: i18n.tc('log', 1)},
            ]
        }
    },
    {
        path: '/marketing/contacts',
        name: 'marketing-contacts',
        component: () => import(/* webpackChunkName: "contacts" */ '../pages/marketing/PageContacts'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.marketing.contacts'),
            breadcrumb: [
                {name: i18n.t('marketing')},
                {name: i18n.tc('contact', 2), to: 'marketing-contacts'},
            ]
        }
    },
    {
        path: '/marketing/segments',
        name: 'marketing-segments',
        component: () => import(/* webpackChunkName: "segments" */ '../pages/marketing/PageSegments'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.marketing.segments'),
            breadcrumb: [
                {name: i18n.t('marketing')},
                {name: i18n.tc('segment', 2), to: 'marketing-segments'},
            ]
        }
    },
    {
        path: '/marketing/channels',
        name: 'marketing-channels',
        component: () => import(/* webpackChunkName: "channels" */ '../pages/marketing/PageChannels'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.marketing.channels'),
            breadcrumb: [
                {name: i18n.t('marketing')},
                {name: i18n.tc('channel', 2), to: 'marketing-channels'},
            ]
        }
    },
    // Automation
    {
        path: '/automation/events',
        name: 'automation-events',
        component: () => import(/* webpackChunkName: "automation-events" */ '../pages/automation/PageEvents'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.automation.events'),
            breadcrumb: [
                {name: i18n.tc('automation', 1)},
                {name: i18n.tc('event', 2), to: 'automation-events'},
            ]
        }
    },
    {
        path: '/automation/rules',
        name: 'automation-rules',
        component: () => import(/* webpackChunkName: "automation-rules" */ '../pages/automation/PageRules'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.automation.rules'),
            breadcrumb: [
                {name: i18n.tc('automation', 1)},
                {name: i18n.tc('rule', 2), to: 'automation-rules'},
            ]
        }
    },
    {
        path: '/automation/workflows',
        name: 'automation-workflows',
        component: () => import(/* webpackChunkName: "automation-workflows" */ '../pages/automation/PageWorkflows'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.automation.workflows'),
            breadcrumb: [
                {name: i18n.tc('automation', 1)},
                {name: i18n.tc('workflow', 2), to: 'automation-workflows'},
            ]
        }
    },
    {
        path: '/automation/process-schedule',
        name: 'automation-process-schedule',
        component: () => import(/* webpackChunkName: "automation-process-schedule" */ '../pages/automation/PageProcessSchedule'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.automation.process-schedule'),
            breadcrumb: [
                {name: i18n.tc('automation', 1)},
                {name: i18n.tc('process-schedule', 2), to: 'automation-process-schedule'},
            ]
        }
    },
    {
        path: '/automation/process-jobs',
        name: 'automation-process-jobs',
        component: () => import(/* webpackChunkName: "automation-process-jobs" */ '../pages/automation/PageProcessJobs'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.automation.process-jobs'),
            breadcrumb: [
                {name: i18n.tc('automation', 1)},
                {name: i18n.tc('process-job', 2), to: 'automation-process-jobs'},
            ]
        }
    },
    {
        path: '/automation/queue',
        name: 'automation-queue',
        component: () => import(/* webpackChunkName: "automation-queue" */ '../pages/automation/PageQueue'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.automation.queue'),
            breadcrumb: [
                {name: i18n.t('marketing')},
                {name: i18n.tc('queue', 1), to: 'automation-queue'},
            ]
        }
    },
    // Storage
    {
        path: '/storage/library',
        name: 'storage-library',
        component: () => import(/* webpackChunkName: "storage-library" */ '../pages/storage/PageLibrary'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.storage.library'),
            breadcrumb: [
                {name: i18n.tc('storage', 1)},
                {name: i18n.tc('library', 1), to: 'storage-library'}
            ]
        }
    },
    {
        path: '/storage/databases',
        name: 'storage-databases',
        component: () => import(/* webpackChunkName: "storage-databases" */ '../pages/storage/PageDatabases'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.storage.databases'),
            breadcrumb: [
                {name: i18n.tc('storage', 1)},
                {name: i18n.tc('database', 2), to: 'storage-databases'}
            ]
        }
    },
    // Account
    {
        path: '/account/summary',
        name: 'account-summary',
        component: () => import(/* webpackChunkName: "account-summary" */ '../pages/account/PageSummary'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.account.summary'),
            breadcrumb: [
                {name: i18n.tc('account', 1)},
                {name: i18n.tc('summary', 1), to: 'account-summary'},
            ]
        }
    },
    {
        path: '/account/organization',
        name: 'account-organization',
        component: () => import(/* webpackChunkName: "account-organization" */ '../pages/account/PageOrganization'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.account.organization'),
            breadcrumb: [
                {name: i18n.tc('account', 1)},
                {name: i18n.tc('organization', 1), to: 'account-organization'},
            ]
        }
    },
    {
        path: '/account/products',
        name: 'account-products',
        component: () => import(/* webpackChunkName: "account-products" */ '../pages/account/PageProducts'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.account.products'),
            breadcrumb: [
                {name: i18n.tc('account', 1)},
                {name: i18n.tc('product', 2), to: 'account-products'},
            ]
        }
    },
    {
        path: '/account/billing',
        name: 'account-billing',
        redirect: { name: 'account-billing-orders' }
    },
    {
        path: '/account/billing/orders',
        name: 'account-billing-orders',
        component: () => import(/* webpackChunkName: "account-billing-orders" */ '../pages/account/billing/PageOrders'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.account.billing.orders'),
            breadcrumb: [
                {name: i18n.tc('account', 1)},
                {name: i18n.tc('billing', 1)},
                {name: i18n.tc('order', 2), to: 'account-billing-orders'},
            ]
        }
    },
    {
        path: '/account/billing/invoices',
        name: 'account-billing-invoices',
        component: () => import(/* webpackChunkName: "account-billing-invoices" */ '../pages/account/billing/PageInvoices'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.account.billing.invoices'),
            breadcrumb: [
                {name: i18n.tc('account', 1)},
                {name: i18n.tc('billing', 1)},
                {name: i18n.tc('invoice', 2), to: 'account-billing-invoices'},
            ]
        }
    },
    {
        path: '/account/payment-methods',
        name: 'account-payment-methods',
        component: () => import(/* webpackChunkName: "account-payment-methods" */ '../pages/account/PagePaymentMethods'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.account.payment-methods'),
            breadcrumb: [
                {name: i18n.tc('account', 1)},
                {name: i18n.tc('payment-method', 2), to: 'account-payment-methods'},
            ]
        }
    },
    // Settings
    {
        path: '/settings/general',
        name: 'settings-general',
        component: () => import(/* webpackChunkName: "settings-general" */ '../pages/settings/PageGeneral'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.settings.general'),
            breadcrumb: [
                {name: i18n.tc('setting', 2)},
                {name: i18n.t('general'), to: 'settings-general'},
            ]
        }
    },
    {
        path: '/settings/logging',
        name: 'settings-logging',
        component: () => import(/* webpackChunkName: "settings-logging" */ '../pages/settings/PageLogging'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.settings.logging'),
            breadcrumb: [
                {name: i18n.tc('setting', 2)},
                {name: i18n.tc('logging', 2), to: 'settings-logging'},
            ]
        }
    },
    {
        path: '/settings/notifications',
        name: 'settings-notifications',
        component: () => import(/* webpackChunkName: "settings-notifications" */ '../pages/settings/PageNotifications'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.settings.notifications'),
            breadcrumb: [
                {name: i18n.tc('setting', 2)},
                {name: i18n.tc('notification', 2), to: 'settings-notifications'},
            ]
        }
    },
    {
        path: '/settings/merchants',
        name: 'settings-merchants',
        component: () => import(/* webpackChunkName: "settings-merchants" */ '../pages/settings/PageMerchants'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.settings.merchants'),
            breadcrumb: [
                {name: i18n.tc('setting', 2)},
                {name: i18n.tc('merchant', 2), to: 'settings-merchants'},
            ]
        }
    },
    {
        path: '/settings/service-providers',
        name: 'settings-service-providers',
        component: () => import(/* webpackChunkName: "settings-service-providers" */ '../pages/settings/PageServiceProviders'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.settings.service-providers'),
            breadcrumb: [
                {name: i18n.tc('setting', 2)},
                {name: i18n.tc('service-provider', 2), to: 'settings-service-providers'},
            ]
        }
    },
    {
        path: '/settings/trash-bin',
        name: 'settings-trash-bin',
        component: () => import(/* webpackChunkName: "settings-trash-bin" */ '../pages/settings/PageTrashBin'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.settings.trash-bin'),
            breadcrumb: [
                {name: i18n.tc('setting', 2)},
                {name: i18n.tc('trash-bin', 1), to: 'settings-trash-bin'},
            ]
        }
    },
    // Security
    {
        path: '/security/users',
        name: 'security-users',
        component: () => import(/* webpackChunkName: "security-users" */ '../pages/security/PageUsers'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.security.users'),
            breadcrumb: [
                {name: i18n.tc('security', 1)},
                {name: i18n.tc('user', 2), to: 'security-users'},
            ]
        }
    },
    // Add-ons
    {
        path: '/addons/overview',
        name: 'addons-overview',
        component: () => import(/* webpackChunkName: "addons-overview" */ '../pages/addons/PageOverview'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.add-ons.overview'),
            breadcrumb: [
                {name: i18n.tc('add-on', 2)},
                {name: i18n.tc('overview', 1), to: 'addons-overview'},
            ]
        }
    },
    {
        path: '/addons/suites',
        name: 'addons-suites',
        redirect: { name: 'addons-suites-main' }
    },
    {
        path: '/addons/suites/main',
        name: 'addons-suites-main',
        component: () => import(/* webpackChunkName: "addons-suites-main" */ '../pages/addons/suites/PageMain'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.add-ons.suites.main'),
            breadcrumb: [
                {name: i18n.tc('add-on', 2)},
                {name: i18n.tc('suite', 2)},
                {name: i18n.t('main'), to: 'addons-suites-main'},
            ]
        }
    },
    {
        path: '/addons/suites/additional',
        name: 'addons-suites-additional',
        component: () => import(/* webpackChunkName: "addons-suites-additional" */ '../pages/addons/suites/PageAdditional'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.add-ons.suites.additional'),
            breadcrumb: [
                {name: i18n.tc('add-on', 2)},
                {name: i18n.tc('suite', 2)},
                {name: i18n.t('additional'), to: 'addons-suites-additional'},
            ]
        }
    },
    {
        path: '/addons/linked-services',
        name: 'addons-linked-services',
        component: () => import(/* webpackChunkName: "addons-linked-services" */ '../pages/addons/PageLinkedServices'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.add-ons.linked-services'),
            breadcrumb: [
                {name: i18n.tc('add-on', 2)},
                {name: i18n.tc('linked-service', 2), to: 'addons-linked-services'},
            ]
        }
    },
    {
        path: '/addons/professional-services',
        name: 'addons-professional-services',
        component: () => import(/* webpackChunkName: "addons-professional-services" */ '../pages/addons/PageProfessionalServices'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.add-ons.professional-services'),
            breadcrumb: [
                {name: i18n.tc('add-on', 2)},
                {name: i18n.t('professional-services'), to: 'addons-professional-services'},
            ]
        }
    },
    {
        path: '/addons/devices',
        name: 'addons-devices',
        component: () => import(/* webpackChunkName: "addons-devices" */ '../pages/addons/PageDevices'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.add-ons.devices'),
            breadcrumb: [
                {name: i18n.tc('add-on', 2)},
                {name: i18n.tc('device', 2), to: 'addons-devices'},
            ]
        }
    },
    {
        path: '/addons/applications',
        name: 'addons-applications',
        component: () => import(/* webpackChunkName: "addons-applications" */ '../pages/addons/PageApplications'),
        meta: {
            requiresAuth: true,
            title: i18n.t('routes.add-ons.applications'),
            breadcrumb: [
                {name: i18n.tc('add-on', 2)},
                {name: i18n.tc('application', 2), to: 'addons-applications'},
            ]
        }
    },

    // Special pages
    {
        path: '/sso/:jwt',
        name: 'SSO',
        component: () => import(/* webpackChunkName: "SSO" */ '../pages/PageSSO')
    },

    // 404
    {
        path: '/404',
        name: '404',
        component: () => import(/* webpackChunkName: "404" */ '../pages/Page404')
    },
    {
        path: '*',
        redirect: '/404'
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router;

router.beforeEach((to, from, next) => {
    // Hide the backdrop if it's been left visible by another component/page
    if (store.state.backdrop.visible) {
        store.commit('backdrop/hide');
    }

    // Redirect to the login page if not logged in and the route requires auth
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters['USER/isLoggedIn'] === false) {
            next({name: 'sign-in'});
        }
    }

    // Redirect all HOME links to the Dashboard Overview
    if (to.matched.some(record => record.name === 'home')) {
        next({name: 'dashboard-overview'});
    }

    // Always call next()
    next();
});

router.afterEach((to) => {
    let title = process.env.VUE_APP_TITLE;

    Vue.nextTick(() => {
        if (to.meta.title) {
            title = to.meta.title + ' | ' + title;
        }

        document.title = title;
    });
});
