import LiftService from "@/services/lift.service";

const contact = {};
const contacts = {
    page: 1,
    itemsPerPage: 10,
    limit: null,
    total: 0,
    list: []
}

const contactModule = {
    namespaced: true,
    state: {contact, contacts},
    actions: {
        CREATE({commit}, payload) {
            commit('loading/CONTACT_CREATE', true, {root: true});
            commit('clearContact');
            return new Promise((resolve, reject) => {
                LiftService.CONTACT_CREATE(payload.address, payload.contactInformation)
                    .then(contact => {
                        commit('populateContact', contact);
                        commit('loading/CONTACT_CREATE', false, {root: true});
                        resolve(contact);
                    })
                    .catch(error => {
                        commit('loading/CONTACT_CREATE', false, {root: true});
                        reject(error);
                    });
            });
        },
        GET({commit}, contactUniqueId) {
            commit('loading/CONTACT_GET', true, {root: true});
            commit('clearContact');
            return new Promise((resolve, reject) => {
                LiftService.CONTACT_GET(contactUniqueId)
                    .then(contact => {
                        commit('populateContact', contact);
                        commit('loading/CONTACT_GET', false, {root: true});
                        resolve(contact);
                    })
                    .catch(error => {
                        commit('loading/CONTACT_GET', false, {root: true});
                        reject(error);
                    });
            });
        },
        UPDATE({commit}, payload) {
            commit('loading/CONTACT_UPDATE', true, {root: true});
            commit('clearContact');
            return new Promise((resolve, reject) => {
                LiftService.CONTACT_UPDATE(
                    payload.contactUniqueId,
                    payload.address,
                    payload.contactInformation
                )
                    .then(contact => {
                        commit('populateContact', contact);
                        commit('loading/CONTACT_UPDATE', false, {root: true});
                        resolve(contact);
                    })
                    .catch(error => {
                        commit('loading/CONTACT_UPDATE', false, {root: true});
                        reject(error);
                    });
            });
        },
        DELETE({commit}, contactUniqueId) {
            commit('loading/CONTACT_DELETE', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.CONTACT_DELETE(contactUniqueId)
                    .then(contact => {
                        commit('loading/CONTACT_DELETE', false, {root: true});
                        resolve(contact);
                    })
                    .catch(error => {
                        commit('loading/CONTACT_DELETE', false, {root: true});
                        reject(error);
                    });
            });
        },
        LIST({commit}, payload) {
            commit('loading/CONTACT_LIST', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.CONTACT_LIST(
                    payload.filter,
                    payload.order,
                    payload.page,
                    payload.itemsPerPage,
                    payload.limit
                )
                    .then(contacts => {
                        commit('populateContacts', contacts);
                        commit('loading/CONTACT_LIST', false, {root: true});
                        resolve(contacts);
                    })
                    .catch(error => {
                        commit('clearContacts');
                        commit('loading/CONTACT_LIST', false, {root: true});
                        reject(error);
                    });
            });
        },
    },
    mutations: {
        clearContacts(state) {
            state.contacts = {
                page: 1,
                itemsPerPage: 10,
                limit: null,
                total: 0,
                list: []
            }
        },
        populateContacts(state, contacts) {
            state.contacts = contacts;
        },
        clearContact(state) {
            state.contact = {};
        },
        populateContact(state, contact) {
            state.contact = contact;
        },
    }
};

export default contactModule;