import LiftService from "@/services/lift.service";

const rule = {}
const rules = {
    page: 1,
    itemsPerPage: 10,
    limit: null,
    total: 0,
    list: []
}

const ruleModule = {
    namespaced: true,
    state: {rule, rules},
    actions: {
        CREATE({commit}, payload) {
            commit('loading/RULE_CREATE', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.RULE_CREATE(
                    payload.ruleUniqueId,
                    payload.ruleDescShort,
                    payload.ruleDescLong,
                    payload.ruleComment,
                    payload.ruleType,
                    payload.ruleStatus,
                    payload.ruleConfig
                )
                    .then(rule => {
                        commit('loading/RULE_CREATE', false, {root: true});
                        commit('populateRule', rule);
                        resolve(rule);
                    })
                    .catch(error => {
                        commit('loading/RULE_CREATE', false, {root: true});
                        reject(error);
                    });
            });
        },
        GET({commit}, ruleUniqueId) {
            commit('loading/RULE_GET', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.RULE_GET(ruleUniqueId)
                    .then(rule => {
                        commit('loading/RULE_GET', false, {root: true});
                        commit('populateRule', rule);
                        resolve(rule);
                    })
                    .catch(error => {
                        commit('loading/RULE_GET', false, {root: true});
                        reject(error);
                    });
            });
        },
        UPDATE({commit}, payload) {
            commit('loading/RULE_UPDATE', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.RULE_UPDATE(
                    payload.ruleUniqueId,
                    payload.ruleDescShort,
                    payload.ruleDescLong,
                    payload.ruleComment,
                    payload.ruleConfig
                )
                    .then(rule => {
                        commit('loading/RULE_UPDATE', false, {root: true});
                        commit('populateRule', rule);
                        resolve(rule);
                    })
                    .catch(error => {
                        commit('loading/RULE_UPDATE', false, {root: true});
                        reject(error);
                    });
            });
        },
        DELETE({commit}, ruleUniqueId) {
            commit('loading/RULE_DELETE', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.RULE_DELETE(ruleUniqueId)
                    .then(rule => {
                        commit('loading/RULE_DELETE', false, {root: true});
                        commit('populateRule', rule);
                        resolve(rule);
                    })
                    .catch(error => {
                        commit('loading/RULE_DELETE', false, {root: true});
                        reject(error);
                    });
            });
        },
        LIST({commit}, payload) {
            commit('loading/RULE_LIST', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.RULE_LIST(
                    payload.filter,
                    payload.order,
                    payload.page,
                    payload.itemsPerPage
                )
                    .then(rules => {
                        commit('loading/RULE_LIST', false, {root: true});
                        commit('populateRules', rules);
                        resolve(rules);
                    })
                    .catch(error => {
                        commit('clearRules');
                        commit('loading/RULE_LIST', false, {root: true});
                        reject(error);
                    });
            });
        },
    },
    mutations: {
        clearRules(state) {
            state.rules = {
                page: 1,
                itemsPerPage: 10,
                limit: null,
                total: 0,
                list: []
            }
        },
        populateRules(state, rules) {
            state.rules = rules;
        },
        populateRule(state, rule) {
            state.rule = rule;
        },
    }
};

export default ruleModule;
