import LiftService from "@/services/lift.service";

const processSchedule = {}
const processSchedules = {
    page: 1,
    itemsPerPage: 10,
    limit: null,
    total: 0,
    list: []
}

const processScheduleModule = {
    namespaced: true,
    state: {processSchedule, processSchedules},
    actions: {
        GET({commit}, processScheduleUniqueId) {
            commit('loading/PROCESS_SCHEDULE_GET', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.PROCESS_SCHEDULE_GET(processScheduleUniqueId)
                    .then(processSchedule => {
                        commit('loading/PROCESS_SCHEDULE_GET', false, {root: true});
                        commit('populateProcessSchedule', processSchedule);
                        resolve(processSchedule);
                    })
                    .catch(error => {
                        commit('loading/PROCESS_SCHEDULE_GET', false, {root: true});
                        reject(error);
                    });
            });
        },
        LIST({commit}, payload) {
            commit('loading/PROCESS_SCHEDULE_LIST', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.PROCESS_SCHEDULE_LIST(
                    payload.filter,
                    payload.order,
                    payload.page,
                    payload.itemsPerPage
                )
                    .then(processSchedules => {
                        commit('loading/PROCESS_SCHEDULE_LIST', false, {root: true});
                        commit('populateProcessSchedules', processSchedules);
                        resolve(processSchedules);
                    })
                    .catch(error => {
                        commit('clearProcessSchedules');
                        commit('loading/PROCESS_SCHEDULE_LIST', false, {root: true});
                        reject(error);
                    });
            });
        },
    },
    mutations: {
        clearProcessSchedules(state) {
            state.processSchedules = {
                page: 1,
                itemsPerPage: 10,
                limit: null,
                total: 0,
                list: []
            }
        },
        populateProcessSchedules(state, processSchedules) {
            state.processSchedules = processSchedules;
        },
        populateProcessSchedule(state, processSchedule) {
            state.processSchedule = processSchedule;
        },
    }
};

export default processScheduleModule;
