import LiftService from "../services/lift.service";
import getCookie from "../lib/get-cookie";
import deleteCookie from "../lib/delete-cookie";
import jwtDecode from "jwt-decode";
import setCookie from "../lib/set-cookie";

const user = getCookie('user');
const profile = {}

const initialState = user
    ? {status: {loggedIn: true}, user, profile}
    : {status: {loggedIn: false}, user: null, profile};

const userModule = {
    namespaced: true,
    state: initialState,
    actions: {
        LOGIN({commit}, payload) {
            commit('loading/USER_LOGIN', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.USER_LOGIN(payload.username, payload.password)
                    .then(user => {
                        const decodedJwt = jwtDecode(user.token);

                        setCookie(
                            'user',
                            JSON.stringify(
                                {
                                    key: user.key,
                                    secret: user.secret,
                                    email: user.email,
                                    firstName: user.firstName,
                                    lastName: user.lastName,
                                    token: user.token,
                                    partyUniqueId: decodedJwt.eusdp.partyUniqueId,
                                    awsAccessKeyId: user.aws.key,
                                    awsSecretAccessKey: user.aws.secret
                                }
                            ),
                            {
                                expires: 86400
                            }
                        );

                        commit('populateUser', user);
                        commit('loading/USER_LOGIN', false, {root: true});
                        resolve(user);
                    })
                    .catch(error => {
                        commit('clearUser');
                        commit('loading/USER_LOGIN', false, {root: true});
                        reject(error);
                    });
            });
        },
        LOGOUT({commit}) {
            commit('loading/USER_LOGOUT', true, {root: true});
            return new Promise((resolve) => {
                deleteCookie('user');
                commit('clearUser');
                commit('loading/USER_LOGOUT', false, {root: true});
                resolve();
            });
        },
        LOST_PASSWORD({commit}, username) {
            commit('loading/USER_LOST_PASSWORD', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.USER_LOST_PASSWORD(username)
                    .then(() => {
                        commit('loading/USER_LOST_PASSWORD', false, {root: true});
                        resolve();
                    })
                    .catch(error => {
                        commit('loading/USER_LOST_PASSWORD', false, {root: true});
                        reject(error);
                    });
            });
        },
        RECOVER_PASSWORD({commit}, payload) {
            commit('loading/USER_RECOVER_PASSWORD', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.USER_RECOVER_PASSWORD(payload.token, payload.newPassword)
                    .then(() => {
                        commit('loading/USER_RECOVER_PASSWORD', false, {root: true});
                        resolve();
                    })
                    .catch(error => {
                        commit('loading/USER_RECOVER_PASSWORD', false, {root: true});
                        reject(error);
                    });
            });
        },
        GET_PROFILE({commit}) {
            commit('loading/USER_GET_PROFILE', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.USER_GET_PROFILE()
                    .then(profile => {
                        commit('populateProfile', profile);
                        commit('loading/USER_GET_PROFILE', false, {root: true});
                        resolve(profile);
                    })
                    .catch(error => {
                        commit('loading/USER_GET_PROFILE', false, {root: true});
                        reject(error);
                    });
            });
        },
        UPDATE_PROFILE({commit}, payload) {
            commit('loading/USER_UPDATE_PROFILE', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.USER_UPDATE_PROFILE(
                    payload.email,
                    payload.vatId,
                    payload.salutation,
                    payload.firstName,
                    payload.lastName,
                    payload.company,
                    payload.street,
                    payload.postcode,
                    payload.city,
                    payload.state,
                    payload.country,
                    payload.birthday,
                    payload.billingAddress,
                    payload.shippingAddress
                )
                    .then(profile => {
                        commit('populateProfile', profile);
                        commit('loading/USER_UPDATE_PROFILE', false, {root: true});
                        resolve(profile);
                    })
                    .catch(error => {
                        commit('loading/USER_UPDATE_PROFILE', false, {root: true});
                        reject(error);
                    });
            });
        },
        UPDATE_PASSWORD({commit}, payload) {
            commit('loading/USER_UPDATE_PASSWORD', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.USER_UPDATE_PASSWORD(payload.username, payload.password, payload.newPassword)
                    .then(profile => {
                        commit('loading/USER_UPDATE_PASSWORD', false, {root: true});
                        resolve(profile);
                    })
                    .catch(error => {
                        commit('loading/USER_UPDATE_PASSWORD', false, {root: true});
                        reject(error);
                    });
            });
        }
    },
    mutations: {
        clearUser(state) {
            state.user = null;
        },
        populateUser(state, user) {
            state.user = user;
        },

        populateProfile(state, profile) {
            state.profile = profile;
        }
    },
    getters: {
        isLoggedIn: state => {
            return state.user !== null;
        }
    }
};

export default userModule;
