import LiftService from "@/services/lift.service";

const workflow = {}
const workflows = {
    page: 1,
    itemsPerPage: 10,
    limit: null,
    total: 0,
    list: []
}

const workflowModule = {
    namespaced: true,
    state: {workflow, workflows},
    actions: {
        CREATE({commit}, payload) {
            commit('loading/WORKFLOW_CREATE', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.WORKFLOW_CREATE(
                    payload.workflowUniqueId,
                    payload.workflowDescShort,
                    payload.workflowDescLong,
                    payload.workflowComment,
                    payload.workflowType,
                    payload.workflowStatus,
                    payload.workflowConfig
                )
                    .then(workflow => {
                        commit('loading/WORKFLOW_CREATE', false, {root: true});
                        commit('populateWorkflow', workflow);
                        resolve(workflow);
                    })
                    .catch(error => {
                        commit('loading/WORKFLOW_CREATE', false, {root: true});
                        reject(error);
                    });
            });
        },
        GET({commit}, workflowUniqueId) {
            commit('loading/WORKFLOW_GET', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.WORKFLOW_GET(workflowUniqueId)
                    .then(workflow => {
                        commit('loading/WORKFLOW_GET', false, {root: true});
                        commit('populateWorkflow', workflow);
                        resolve(workflow);
                    })
                    .catch(error => {
                        commit('loading/WORKFLOW_GET', false, {root: true});
                        reject(error);
                    });
            });
        },
        UPDATE({commit}, payload) {
            commit('loading/WORKFLOW_UPDATE', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.WORKFLOW_UPDATE(
                    payload.workflowUniqueId,
                    payload.workflowDescShort,
                    payload.workflowDescLong,
                    payload.workflowComment,
                    payload.workflowStatus,
                    payload.workflowConfig
                )
                    .then(workflow => {
                        commit('loading/WORKFLOW_UPDATE', false, {root: true});
                        commit('populateWorkflow', workflow);
                        resolve(workflow);
                    })
                    .catch(error => {
                        commit('loading/WORKFLOW_UPDATE', false, {root: true});
                        reject(error);
                    });
            });
        },
        DELETE({commit}, workflowUniqueId) {
            commit('loading/WORKFLOW_DELETE', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.WORKFLOW_DELETE(workflowUniqueId)
                    .then(workflow => {
                        commit('loading/WORKFLOW_DELETE', false, {root: true});
                        commit('populateWorkflow', workflow);
                        resolve(workflow);
                    })
                    .catch(error => {
                        commit('loading/WORKFLOW_DELETE', false, {root: true});
                        reject(error);
                    });
            });
        },
        LIST({commit}, payload) {
            commit('loading/WORKFLOW_LIST', true, {root: true});
            return new Promise((resolve, reject) => {
                LiftService.WORKFLOW_LIST(
                    payload.filter,
                    payload.order,
                    payload.page,
                    payload.itemsPerPage
                )
                    .then(workflows => {
                        commit('loading/WORKFLOW_LIST', false, {root: true});
                        commit('populateWorkflows', workflows);
                        resolve(workflows);
                    })
                    .catch(error => {
                        commit('clearWorkflows');
                        commit('loading/WORKFLOW_LIST', false, {root: true});
                        reject(error);
                    });
            });
        },
    },
    mutations: {
        clearWorkflows(state) {
            state.workflows = {
                page: 1,
                itemsPerPage: 10,
                limit: null,
                total: 0,
                list: []
            }
        },
        populateWorkflow(state, workflow) {
            state.workflow = workflow;
        },
        populateWorkflows(state, workflows) {
            state.workflows = workflows;
        },
    }
};

export default workflowModule;
